<template>
    <div class="about-us-content-container">
        <!-- Title of page -->
        <div class="page-title-container">
            {{ text['aboutUs'].title }}
        </div>

        <hr class="line-separator y-margins line-separator-small-screen" />

        <div class="team-container" id="team-container">
            <div class="section-title">
                {{ text['aboutUs'].team }}
            </div>
            <div class="worker-container mt-5" :class="[Number(key)%2 == 0 ? 'worker-container-reverse' : '']" v-for="key in Object.keys(text['aboutUs'].workers)" :key="key">
                <div class="worker-image" :class="[Number(key)%2 == 0 ? 'worker-image-reverse' : '']">
                    <img :src="text['aboutUs'].workers[key].image">
                </div>
                <div class="worker-description">
                    <div class="worker-name">
                        {{ text['aboutUs'].workers[key].name }}
                    </div>
                    <div class="worker-text" v-for="(lines, i) in text['aboutUs'].workers[key].description" :key="i">
                        {{ lines }}
                        <br v-if="i != text['aboutUs'].workers[key].description.length-1"/><br v-if="i != text['aboutUs'].workers[key].description.length-1"/>
                    </div>
                </div>
            </div>
        </div>
        
        <hr class="line-separator y-margins line-separator-small-screen" />

        <div class="center-container" id="center-container">
            <div class="center-title section-title">
                {{ text['aboutUs'].center.title }}
            </div>
            <div class="center-description">
                <span v-for="(lines, i) in text['aboutUs'].center.description" :key="i">
                    {{ lines }}
                    <br v-if="i != text['aboutUs'].center.description.length-1"/><br v-if="i != text['aboutUs'].center.description.length-1"/>
                </span>
            </div>
        </div>

        <br />

        <div class="carousel-container">
            <div id="carouselCenter" class="carousel slide carousel-style">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselCenter" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselCenter" data-bs-slide-to="1" aria-label="Slide 2"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item" :class="[key == 1 ? 'active' : '']" v-for="key in Object.keys(text['aboutUs'].center.images)" :key="key">
                        <img :src="text['aboutUs'].center.images[key]" class="d-block w-100">
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselCenter" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselCenter" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>

    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "Services",
    data() {
        return {
            // mainLinks: {
            //     "Eva Fructuoso Díaz": {
            //         link: "#action-areas-container",
            //         text: "Más información",
            //         image: "https://fisart.es/wp-content/uploads/2020/03/eva.jpg",
            //     },
            //     "El centro": {
            //         link: "#fisio-container",
            //         text: "Más información",
            //         image: "https://www.fisio-poblenou.com/wp-content/uploads/2021/02/2_1.jpg",
            //     },
            // },
            // workers: {
            //     1: {
            //         name: "Eva Fructuoso Diaz",
            //         image: "https://fisart.es/wp-content/uploads/2020/03/eva.jpg",
            //         description:  [
            //             "Finalicé mis estudios de fisioterapeuta en 2015 desde entonces siempre he seguido formándome y queriendo conocer más sobre el cuerpo humano, me apasiona mi trabajo y poder ayudar a la gente. Al salir de la carrera estuve trabajando a domicilio, con la camilla a cuestas para todos los lados, al poco tiempo tuve la gran suerte de entrar en un centro de neuropediatría donde aprendí nuevos valores en la vida, sentía que necesitaba aprender mucho más, así que decidí estudiar osteopatía, después de 5 años, finalicé el master en 2022. A nivel laboral, tras 5 años en el centro de neurología, entré a trabajar en un centro de fisioterapia, donde además de bebés y niños, trataba adultos con diversas patologías, después de 3 años más de sumar experiencias he decidido abrir mi propia consulta.",
            //             "Seguiré formándome siempre, para poder seguir ayudando a todas aquellas personas que me queráis conocer."
            //         ]
            //     }
            // },
            // center: {
            //     title: "Centro",
            //     description: [
            //         "Ossis es un centro privado de fisioterapia y osteopatía situado en La Beguda Alta fundado en 2023.",
            //         "En este centro nos importa mucho la calidad de vida de las personas, por lo que se ofrece un trabajo individualizado con el paciente basado principalmente en la terapia manual con técnicas y ejercicios personalizados."
            //     ],
            //     images: {
            //         1: "https://www.fisio-poblenou.com/wp-content/uploads/2021/02/2_1.jpg",
            //         2: "https://www.fisio-poblenou.com/wp-content/uploads/2021/02/IMG_20191001_121845_2.jpg",
            //         3: "https://www.fisio-poblenou.com/wp-content/uploads/2021/02/24.jpg",
            //     }
            // },
        }
    },
    computed: {
        ...mapGetters(["idSelected", "text"]),
    },
    methods: {
        ...mapMutations(["setIdSelected"]),
        scrollMeTo(refName) {
            let element = document.querySelector(refName);
            let top = element.offsetTop - 150;
            window.scrollTo(0, top);
        }
    },
    mounted() {
        if(this.idSelected.length > 0) {
            this.scrollMeTo(this.idSelected);
            this.setIdSelected("");
        } else {
            setTimeout(function () {
                window.scrollTo(0, 0);
            }, 1);
        }
    },
}
</script>

<style scoped>

.about-us-content-container {
    margin: auto var(--horizontal-margin);
}

.team-container {
    margin-bottom: 4rem;
}

.section-title {
    font-size: 1.75rem;
    text-align: center;
    font-weight: bold;
    /* margin-top: 3rem; */
}

.worker-container {
    display: flex;
    margin-top: 2rem;
}

.worker-container-reverse {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 2rem;
}

.worker-image {
    margin: auto 0;
    margin-right: 75px;
}

.worker-image-reverse {
    margin: auto 0;
    margin-left: 75px;
    margin-right: 0;
}

.worker-image > img {
    width: 250px;
    border-radius: 15px;
    box-shadow: 0 5px 20px -5px var(--blue-color);
}

.worker-description {
    margin: auto 0;
    text-align: justify;
}

.worker-name {
    font-size: 1.35rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
}


/* .center-title {
    margin-top: 5rem;
} */

.center-description {
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 2rem;
}

/* Carousel */
.carousel-container {
    margin-left: calc(var(--horizontal-margin) * -1);
    margin-right: calc(var(--horizontal-margin) * -1);
    background: rgba(0, 0, 0, 0.15);
    /* border-top: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); */
    margin-top: 2rem;
    /* padding: 1rem 0; */
    margin-bottom: -1rem;
    /* margin-bottom: 5rem; */
}

.carousel-style {
    width: 400px;
    margin: 0 auto;
}

@media screen and (max-width: 992px) {
    .worker-container {
        flex-direction: column-reverse;
        font-size: 0.9rem;
        gap: 2rem;
    }

    .worker-name {
        text-align: center;
    }

    .worker-image {
        margin: 0 auto;
    }

    .center-container {
        font-size: 0.9rem;
    }
}
/* MAX SCREEN 768px */
@media screen and (max-width: 768px) {
    .center-description {
        text-align: justify;
    }

    .carousel-style {
        width: 250px;
    }

    .team-container {
        margin-bottom: 3.4rem;
    }
}
</style>