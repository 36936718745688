<template>
<div class="body-container">
  <div>
    <div class="navbar-small-screen small-screen menu-closed">
      <div class="language-container">
          <span class="icon icon-24 icon-lang icon-cat" @click="setLang('cat')"></span>
          <span class="icon icon-24 icon-lang icon-spain" @click="setLang('es')"></span>
      </div>
      <router-link class="navbar-element" :to="{name: 'home'}" @click="openCloseMenu">{{ text.navBar.home }}</router-link>
      <router-link class="navbar-element" :to="{name: 'about-us'}" @click="openCloseMenu">{{ text.navBar.aboutUs }}</router-link>
      <router-link class="navbar-element" :to="{name: 'services'}" @click="openCloseMenu">{{ text.navBar.services }}</router-link>
      <router-link class="navbar-element" :to="{name: 'contact-us'}" @click="openCloseMenu">{{ text.navBar.contactUs }}</router-link>
      <a class="navbar-element fw-bold" :href="text.navBar.appointmentBtn" @click="openCloseMenu">{{ text.navBar.takeAppointment }}</a>
    </div>
    <div class="header-menu-small-screen small-screen">
      <span class="icon icon-64 icon-logo"></span>
      <div id="btn-menu" class="icon icon-24 icon-menu small-screen btn-menu" @click="openCloseMenu"></div>
    </div>
    <div class="margin-top-header"></div>
    <div class="blocking-background hidden"></div>
  </div>
  <header class="header-container big-screen" v-if="!debug">
    <div class="language-container">
        <span class="icon icon-24 icon-lang icon-cat" @click="setLang('cat')"></span>
        <span class="icon icon-24 icon-lang icon-spain" @click="setLang('es')"></span>
    </div>
    <span class="icon icon-100 icon-logo"></span>
    <nav class="navbar-container">
      <router-link class="navbar-element" :to="{name: 'home'}">{{ text.navBar.home }}</router-link>
      <router-link class="navbar-element" :to="{name: 'about-us'}">{{ text.navBar.aboutUs }}</router-link>
      <router-link class="navbar-element" :to="{name: 'services'}">{{ text.navBar.services }}</router-link>
      <router-link class="navbar-element" :to="{name: 'contact-us'}">{{ text.navBar.contactUs }}</router-link>
      <a class="navbar-element fw-bold" :href="text.navBar.appointmentBtn">{{ text.navBar.takeAppointment }}</a>
    </nav>
  </header>
  <router-view class="main-container background-mai-color" />
  <div class="space-filler background-mai-color" :class="[$route.name == 'about-us' ? 'padding-top' : '']" v-if="!debug"></div>
  <footer class="footer-container" v-if="!debug">
    <span>
      Copyright © {{ currentYear }} Ossis | 
      <router-link class="footer-link" :to="{name: 'aviso-legal'}">{{ langText[lang].footer1 }}</router-link> | 
      <router-link class="footer-link" :to="{name: 'politica-privacidad'}">{{ langText[lang].footer2 }}</router-link>
    </span>
  </footer>
  <appointment-button :btn-text="text.navBar.appointmentBtnText" @click="goToLink(text.navBar.appointmentBtn)" />
</div>
  
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import AppointmentButton from './components/AppointmentButton.vue';

export default {
  components: {
    AppointmentButton,
  },
  data() {
    return {
      langText: {
        cat: {
          footer1: "Avís legal",
          footer2: "Política de privacitat"
        },
        es: {
          footer1: "Aviso legal",
          footer2: "Política de privacidad"
        }
      },
    }
  },
  computed: {
    ...mapGetters(["debug", "text", "lang"]),
    currentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    ...mapMutations(["setLang"]),
    goToLink(link) {
      window.location.href = link;
    },
    openCloseMenu() {
      const btnMenu = document.getElementById("btn-menu");
      const navbarManu = document.querySelector(".navbar-small-screen");
      const blockingBackground = document.querySelector(".blocking-background");
      if(Object.values(btnMenu.classList).includes("icon-menu")) {
        btnMenu.classList.remove("icon-menu");
        btnMenu.classList.add("turn-90-deg");
        btnMenu.classList.add("icon-cross");
        navbarManu.classList.remove("menu-closed");
        blockingBackground.classList.remove("hidden");
      } else {
        btnMenu.classList.remove("icon-cross");
        btnMenu.classList.remove("turn-90-deg");
        btnMenu.classList.add("icon-menu");
        navbarManu.classList.add("menu-closed");
        blockingBackground.classList.add("hidden");
      }
    }
  },
  mounted() {
    const scriptEl1 = document.createElement('script');
    scriptEl1.setAttribute('src', 'https://www.nuclisoftware.es/js/jquery-2.2.3.min.js');
    scriptEl1.setAttribute('type', 'text/javascript');
    document.head.appendChild(scriptEl1);
    
    const scriptEl2 = document.createElement('script');
    scriptEl2.setAttribute('src', 'https://www.nuclisoftware.es/js/botonReservas.js');
    scriptEl2.setAttribute('type', 'text/javascript');
    document.head.appendChild(scriptEl2);
  },
}
</script>

<style>
@font-face {
  font-family: "Poppins";
  src: url("@/assets/poppins-v20-latin-regular.woff2");
}

.hidden {
  display: none;
}

.carousel-control-next,
.carousel-control-prev {
  z-index: 0 !important;
}

:root {
  --horizontal-margin: 200px;
  --first-color: black;
  --second-color: rgb(194, 0, 0);
  --blue-color: #00CADF;
  --background-color: rgba(0, 201, 223, 0.05);
}

.main-container {
  font-family: "Poppins";
}

.background-mai-color {
  background-color: var(--background-color);
}

.body-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.header-container {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--horizontal-margin);
  box-shadow: 0 5px 10px -1px var(--blue-color);
  transition: 0.5s;
}

.icon {
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}

.icon-64 {
  width: 64px;
  height: 64px;
  background-size: 64px;
}

.icon-50 {
  width: 50px;
  height: 50px;
  background-size: 50px;
}

.icon-100 {
  --value: 100px;
  width: var(--value);
  height: var(--value);
  background-size: var(--value);
}

.icon-24 {
  width: 24px;
  height: 24px;
  background-size: 24px;
}

.navbar-container {
  align-self: flex-end;
  margin-bottom: 10px;
  display: flex;
  gap: 2.5rem;
}

.navbar-element {
  cursor: pointer;
  text-decoration: none !important;
  color: var(--first-color) !important;
  font-weight: 550 !important;
  font-size: 1.1rem !important;
  transition: 0.5s !important;
  padding: 0 !important;
  border: 0 !important;
  margin: 0 !important;
  z-index: 20;
}

.navbar-element:hover {
  opacity: 0.35;
}

.language-container {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    gap: 10px;
}

.dropdown-container {
  background-color: white !important;
  /* transition: 0.5s !important; */
  margin-top: 10px !important;
}

.dropdown-element {
  transition: 0.5s;
  text-transform: uppercase;
  font-weight: 550 !important;
  font-size: 1rem;
  color: var(--first-color) !important;
}

.dropdown-element:hover {
  background-color: rgba(0, 0, 0, 0) !important;
  opacity: 0.35;
}

.space-filler {
  flex: 1;
}

.padding-top {
  padding-top: 1rem;
}

.footer-container {
  display: flex;
  justify-content: center;
  text-align: center;
  /* margin-top: 1rem; */
  padding: 1rem;
  background-color: var(--blue-color);
  color: white;
}


/* COMMON PAGE STYLE */

.line-separator {
    border-color: var(--blue-color);
    margin: 2rem 10rem;
}

.extra-x-margin {
    margin: 2rem calc( 10rem + var(--horizontal-margin) );
}

.y-margins {
    --margin-y: 4rem;
    margin-top: var(--margin-y);
    margin-bottom: var(--margin-y);
}

.page-title-container {
    margin: 0 var(--horizontal-margin);
    margin-top: 3rem;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
}


.main-link-container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 0 var(--horizontal-margin);
    margin-top: 3rem;
    margin-bottom: 6rem;
}

.main-link-element {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    padding: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    width: 350px;
    box-shadow: 0 5px 10px -5px var(--blue-color);
}

.main-link-title {
    font-size: 1.4rem;
}

.main-link-image-container {
    height: 250px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;
}

.inbetween-img-container {
    --container-width: 225px;
    --container-heihgt: 250px;
    height: var(--container-heihgt);
    width: var(--container-width);
    overflow: hidden;
    position: absolute;
    border-radius: 10px;
}

.main-link-image {
    --image-ratio: 0.6667;
    width: var(--container-width);
    height: calc(var(--container-width) / var(--image-ratio));
    border: 1px solid rgba(0, 0, 0, 0.267);
    border-radius: 10px;
    position: absolute;
    top: -40px;
    left: -0;
}

.align-top {
    top: -20px;
    bottom: auto;
}

.align-bottom {
    top: auto;
    bottom: -20px;
}

.main-link-btn {
    text-decoration: none;
    color: white;
    background: var(--first-color);
    width: 160px;
    text-align: center;
    border-radius: 10px;
    padding: 5px 0;
    margin: 0 auto;
    opacity: .4;
    transition: 0.5s;
    border: none;
}

.main-link-btn:hover {
    opacity: 1;
}


.section-container {
    margin: 6rem 0;
    display: flex;
    flex-direction: column;
}

.section-title-container {
    width: 100%;
    height: 400px;
    position: relative;
}

.section-title-background {
    position: absolute;
    left: -20%;
    width: 60%;
    height: inherit;
    border-radius: 500px;
    background-color: var(--blue-color);
    padding-left: 20%;
    padding-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
}

.section-title-background-right {
    left: auto;
    right: -20%;
    padding-right: 20%;
    padding-left: 5%;
}

.section-title-text {
    padding-left: 45%;
    padding-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
}

.section-title-text-right {
    padding-left: 5%;
    padding-right: 45%;
}

.section-title-title {
    font-size: 3rem;
    color: white;
}

.section-title-description {
    font-size: 1.25rem;
    font-style: italic;
    color: grey;
}

.section-info-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0 var(--horizontal-margin);
    margin-top: 6rem;
    font-size: 1.1rem;
}

.section-info-element {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.section-info-title {
    font-size: 1.25rem;
    font-weight: bold;
}

.section-info-element-text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: 1rem;
    text-align: justify;
}

.section-list-container {
   margin-top: 1rem;
   margin-left: 1rem;
   margin-bottom: 0;
}

.footer-link {
  color: white;
  text-decoration: none;
  transition: 0.5s;
}

.footer-link:hover {
  color: black;
}

/* ICONS URL STYLES */
.icon-logo {
  background-image: url("@/assets/logos/logo OSSIS - manos y letras negras.svg");
}

.icon-phone {
  background-image: url("@/assets/images/phone-blue.png");
}

.icon-whatsapp {
  background-image: url("@/assets/images/whatsapp-blue.png");
}

.icon-instagram {
  background-image: url("@/assets/images/instagram-blue.png");
}

.icon-email {
  background-image: url("@/assets/images/email-blue.png");
}

.icon-location {
  background-image: url("@/assets/images/location-blue.png");
}

.icon-lang {
    cursor: pointer;
}

.icon-cat {
    background-image: url("@/assets/images/cat-flag.png");
}

.icon-spain {
    background-image: url("@/assets/images/spain-flag.png");
}

.icon-calendar {
    background-image: url("@/assets/images/calendar-blue.png");
}

.icon-menu {
    background-image: url("@/assets/images/menu-blue.png");
}

.icon-cross {
    background-image: url("@/assets/images/cross-blue.png");
}

.small-screen {
  display: none;
}

.turn-90-deg {
  transform: rotate(360deg);
}

/* MAX SCREEN 1400px */
@media screen and (max-width: 1400px) {
  :root {
    --horizontal-margin: 100px;
  }
}

/* MAX SCREEN 1200px */
@media screen and (max-width: 1200px) {
  :root {
    --horizontal-margin: 100px;
  }

  .main-link-container {
    flex-wrap: wrap;
  }
}

/* MAX SCREEN 992px */
@media screen and (max-width: 992px) {
  .navbar-element {
    font-size: 1rem !important;
  }
  
  :root {
    --horizontal-margin: 30px;
  }
  
  .main-link-element {
    width: 230px;
    font-size: 1rem;
  }

  .inbetween-img-container {
    --container-width: 150px;
    --container-heihgt: 170px;
  }

  .main-link-image-container {
    height: 170px;
  }

  .main-link-title {
    font-size: 1rem;
  }

  .main-link-btn {
    font-size: 0.9rem;
  }

  .y-margins {
    --margin-y: 2rem;
  }

  .main-link-container {
    margin-top: 2rem;
    margin-bottom: 4rem;
  }

  .section-info-element-text {
    font-size: 0.9rem;
  }

  .section-info-title {
    font-size: 1rem;
  }

  .section-title-container {
      height: 250px;
  }

  .section-title-title {
      font-size: 2rem;
  }

  .section-title-description {
      font-size: .95rem;
  }
}

/* MAX SCREEN 768px */
@media screen and (max-width: 768px) {
  .big-screen {
    display: none;
  }

  .small-screen {
    display: unset;
  }

  .margin-top-header {
    margin-top: 75px;
  }

  .header-menu-small-screen {
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    padding: 0 var(--horizontal-margin);
    z-index: 2;
    background-color: white;
  }

  .btn-menu {
    transition: 0.5s;
    cursor: pointer;
  }

  .menu-closed {
    top: calc(75px - var(--height)) !important;
  }

  .navbar-small-screen {
    --height: 260px;
    background-color: white;
    top: 75px;
    position: fixed;
    width: 100%;
    height: var(--height);
    padding: 1rem;
    transition: 0.5s;
    z-index: 2;
    box-shadow: 0 5px 10px -1px var(--blue-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .language-container {
    gap: 1.5rem;
  }

  .blocking-background {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 1;
    transition: 0.5s;
    opacity: 0.3;
  }

  .section-list-container,
  .section-info-element-text {
    margin-left: 0rem;
  }

  .section-title-container {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .section-title-background {
    position: relative;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    left: 0;
    height: 150px;
    margin: 0;
    width: 80%;
    padding: 0;
  }

  .section-title-background-right {
    left: auto;
    right: -20%;
    border-radius: 500px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .section-title-text,
  .section-title-text-right {
    padding: 0 50px;
    margin-bottom: -1rem;
  }

  .line-separator-small-screen {
    margin: 2rem auto !important;
    padding: 0 !important;
    width: 75% !important;
  }
}

/* MAX SCREEN 576px */
@media screen and (max-width: 576px) {
  .section-list-container,
  .section-info-element-text {
    text-align: left;
  }

  .section-info-container {
    margin-top: 2.5rem;
  }

  .section-container {
    margin-top: 3.25rem;
    margin-bottom: 0;
  }

  .section-title-text {
    margin: 1.5rem 0;
  }

  .footer-container {
    font-size: 0.9rem;
  }
}

/* MAX SCREEN 500px */
@media screen and (max-width: 500px) {
  .section-list-container,
  .section-info-element-text {
    text-align: left;
  }
  
}
</style>
