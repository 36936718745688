<template>
    <div>
        <!-- Title of page -->
        <div class="page-title-container">
            {{textLang[lang].title}}
        </div>

        <div class="main-container">
            <span class="main-description">{{textLang[lang].titleDescription}}</span>
            <div>
                <div class="legal-section-container" v-for="k,i in Object.keys(textLang[lang].sections)" :key="i">
                    <div class="section-title">{{k}}</div>
                    <div>
                        <div class="section-item" :class="[isSectionSubtitle(t)[0] === true ? 'section-subtitle' : '', isSectionSubtitle(t)[0] === 'true' ? 'section-item-group' : '']" v-for="t,j in textLang[lang].sections[k]" :key="j">
                            {{isSectionSubtitle(t)[1]}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "LegalWarning",
    data() {
        return {
            textLang: {
                cat: {
                    title: "AVÍS LEGAL",
                    titleDescription: `En compliment d'allò que estableix l'article 10 de la Llei 34/2002 d'11 de juliol, de serveis de la Societat de la Informació i el Comerç Electrònic (LSSICE), s'informa dels aspectes legals següents:`,
                    sections: {
                        "Propietat del lloc web": [
                            `[g]Titular: Eva Fructuoso Díaz`,
                            `[g]DNI: 47925382Y`,
                            `[g]Telèfon: 661 126 636`,
                            `Contacte: evafd91@gmail.com`,
                        ],
                        "Condicions generals d'ús": [
                            `[s]Titular`,
                            `“Ossis”, com a titular i responsable del present lloc web, posa a disposició dels usuaris aquest document per donar compliment a les obligacions de la Llei 34/2002, de Serveis de la Societat de la Informació i del Comerç Electrònic.`,
                            `[s]Objecte`,
                            `La pàgina web de “Ossis” té com a objecte facilitar al públic informació dels serveis de fisioteràpia que ofereix.`,
                            `[s]Continguts`,
                            `A fi de mantenir actualitzada la informació publicada al portal web, els continguts d'aquest podran ser modificats, corregits, eliminats o afegits en qualsevol moment, per la qual cosa serà convenient comprovar-ne la vigència o exactitud acudint sempre als textos de la pàgina web.`,
                            `El titular del lloc web es reserva la facultat d'efectuar, en qualsevol moment i sense necessitat de previ avís, modificacions i actualitzacions de la informació continguda al seu web o a la configuració i presentació d'aquesta.`,
                            `[s]Acceptació`,
                            `L'ús de qualsevol de les funcionalitats del lloc web implica l'expressa i plena acceptació de les condicions aquí exposades, sens perjudici d'aquelles particulars que es puguin aplicar a alguns dels serveis concrets oferts a través del lloc web.`,
                        ],

                        "Accés a la pàgina web": [
                            `Com s'ha detallat anteriorment, l'accés al web de “Ossis” és responsabilitat exclusiva dels usuaris i suposa acceptar i conèixer l'avís legal, la política de cookies i la política de privacitat.`,
                            `[s]Accés gratuït`,
                            `L'accés a la pàgina web per als usuaris té caràcter gratuït, llevat que es refereixi al cost de la connexió a Internet subministrada pel Proveïdor d'accés a Internet (ISP) contractat pel vostre compte.`,
                        ],

                        "Condicions generals d'ús ": [
                            `La utilització de la pàgina web de “Ossis” per qualsevol persona atribueix la condició d'usuari. L'usuari es compromet a fer-ne un ús conforme al present Avís Legal i a no utilitzar la informació, activitats i serveis que el “Ossis” posa a la vostra disposició per desenvolupar activitats contràries a les lleis.`,
                            `[s]Continguts`,
                            `Els continguts de la pàgina web del “Ossis” són posats a disposició de l'usuari com a informació pròpia i de tercers, per la qual cosa el “Ossis” posarà els mitjans raonables a la seva disposició perquè aquests continguts siguin sempre exactes i actualitzats.`,
                            `[s]Serveis interactius`,
                            `Quant a l'ús dels serveis interactius que permetin la divulgació de continguts per part de l'usuari a través de la pàgina web del “Ossis”, si en algun moment n'hi hagués, serà conforme a la llei i aquest avís legal. “Ossis” no es fa responsable de la informació i continguts emmagatzemats, a títol enunciatiu, però no limitatiu, en fòrums, xats, generadors de blocs, comentaris, xarxes socials o qualsevol altre mitjà que permeti a tercers publicar continguts de forma independent a la pàgina web del prestador. Això no obstant, i en compliment del que disposa l'art. 11 i 16 de la LSSICE, el prestador es posa a disposició de tots els usuaris, Autoritats i Forces de seguretat, i col·laborant de forma activa en la retirada o si és el cas bloqueig de tots aquells continguts que poguessin afectar o contravenir la legislació nacional, o internacional, drets de tercers o moral i ordre públic.`,
                            `[s]Informació veraç`,
                            `En cas de comunicar-se amb “Ossis”, l'usuari haurà de facilitar informació veraç, per la qual cosa l'usuari garantirà sempre l'autenticitat de les dades comunicades. Serà responsabilitat de l'usuari mantenir tota la informació facilitada actualitzada.`,
                            `[s]Usuaris menors d'edat`,
                            `Els usuaris menors han d'obtenir sempre prèviament el consentiment dels pares, tutors o representants legals per a l'ús dels serveis. La responsabilitat en la determinació dels continguts a què accedeix un menor és responsabilitat dels pares, tutors o representants legals. Per evitar l'accés a contingut no apropiat per internet es recomana l'ús de programes o filtres que limitin el contingut disponible.`,
                            `[s]Ús correcte de la pàgina web`,
                            `L'Usuari es compromet a la correcta utilització del web i utilitats que se li proporcionin d'acord amb la llei, aquest Avís Legal i les instruccions i avisos que se li comuniquin. L'Usuari s'obliga a l'ús exclusiu del web i tots els seus continguts, per a fins lícits i no prohibits que no infringeixin la legalitat vigent i/o puguin resultar lesius dels drets legítims de “Ossis”, i/o que puguin causar qualsevol dany o perjudici de manera directa o indirecta.`,
                        ],

                        "Propietat intel·lectual, industrial": [
                            `Tots els elements que formen el lloc web, així com la seva estructura, disseny, fotografies, icones, gràfics, imatges, codi font, logotips, marques i altres signes distintius que hi apareixen, són propietat intel·lectual de “Ossis” o de tercers, llevat que s'especifiqui el contrari, i estan protegits pels corresponents drets de propietat intel·lectual i industrial. L'usuari no podrà utilitzar la marca gràfica o qualsevol altre signe distintiu de “Ossis” dins de la seva pàgina web excepte en els casos expressament autoritzats per “Ossis”. Aquestes autoritzacions es demanaran per escrit a “Ossis”. Per tot això, l'usuari que accedeixi a la pàgina web haurà de respectar en tot moment tots els drets de propietat intel·lectual i industrial de la pàgina web titularitat de “Ossis” o de tercers.`,
                            `La reproducció total o parcial, ús, distribució i comunicació pública requereix l'autorització escrita prèvia per part de “Ossis”. Qualsevol ús no autoritzat prèviament per part de “Ossis” serà considerat un incompliment greu dels drets de propietat intel·lectual o industrial.`,
                            `Igualment estan protegits pels corresponents drets de propietat intel·lectual i industrial les imatges i altres elements gràfics continguts al lloc web. L'ús, reproducció, distribució, comunicació pública, transformació o qualsevol altra activitat similar o anàloga, queda totalment prohibida llevat que hi hagi prèvia i expressa autorització del titular.`,
                            `Respecte a les cites de productes i/o serveis de tercers, el titular del lloc web reconeix a favor dels seus titulars els corresponents drets de propietat industrial i intel·lectual no implicant la seva sola menció o aparició a la web l'existència de drets ni de cap responsabilitat sobre aquests, com tampoc patrocini o recomanació.`,
                            `[s]Enllaços de tercers a la pàgina de “Ossis”`,
                            `Si un usuari vol introduir un enllaç des de la seva pàgina web a la pàgina web de “Ossis” haurà d'obtenir autorització de “Ossis”.`,
                            `El titular del lloc web declara el respecte als drets de propietat intel·lectual i industrial de tercers; per això, si considereu que al nostre lloc web es poguessin estar violant els seus drets, preguem es posi en contacte amb el titular de la pàgina web: evafd91@gmail.com.`,
                        ],

                        "Exclusió de garanties i responsabilitat": [
                            `L'Usuari respondrà pels danys i perjudicis de tota naturalesa que el “Ossis” pugui patir com a conseqüència de l'incompliment de qualsevol de les obligacions a què queda sotmès per aquest Avís Legal.`,
                        ],

                        "Protecció de dades": [
                            `Per a aquells casos que es demanin, tractin o emmagatzemin dades personals es farà de conformitat amb la Política de Privacitat publicada a la pàgina web i que podeu consultar al peu d'aquesta mateixa.`,
                        ],

                        "Política de cookies": [
                            `Actualment la web no utilitza cookies.`,
                        ],

                        "Accés a la pàgina web": [
                            `“Ossis” no es responsabilitza dels danys i perjudicis de qualsevol tipus produïts a l'usuari a conseqüència de fallades a les xarxes de telecomunicacions que produeixin la suspensió, cancel·lació o interrupció del servei de la pàgina web durant la prestació d'aquest o amb caràcter previ.`,
                        ],

                        "Qualitat del servei i dels continguts": [
                            `El titular del lloc web no garanteix la inexistència d'errors en l'accés al web, en el seu contingut, ni que aquest estigui actualitzat, encara que el titular del lloc web desenvoluparà els seus millors esforços per, si s'escau, evitar-los, esmenar-los o actualitzar-los . En cas que l'usuari consideri que hi ha algun contingut que pogués ser susceptible d'aquesta classificació, cal que us poseu en contacte amb el titular del lloc web.`,
                            `Tant l'accés al lloc web, com l'ús que es pugui fer de la informació continguda en aquests, és de responsabilitat exclusiva de qui el realitza.`,
                            `“Ossis” intentarà garantir sempre el correcte funcionament de la pàgina web les 24 hores al dia, els 365 dies de l'any, encara que no es pot descartar la possibilitat que hi hagi determinades causes de força major, catàstrofes naturals, vagues, o qualsevol altra circumstància semblant que facin impossible l'accés a la pàgina web. Per tot això, “Ossis” no es fa responsable dels danys i perjudicis de qualsevol naturalesa que poguessin ocasionar-se a conseqüència de la manca de disponibilitat, manteniment i efectiu funcionament de la web o dels seus serveis i continguts. “Ossis” no es fa responsable de l'existència de virus, programes maliciosos o lesius dels continguts, de l'ús il·lícit, negligent, fraudulent o contrari a aquest Avís legal, o de la manca de licitud, qualitat, fiabilitat, utilitat i disponibilitat dels serveis prestats per tercers a disposició dels usuaris del lloc web.`,
                            `“Ossis” es reserva el dret a interrompre en cas que ho consideri necessari l'accés a la seva pàgina web en qualsevol moment i sense avís previ, ja sigui per motius de seguretat, de control, de manteniment, per errors de subministrament elèctric o per qualsevol altra causa. En resum, “Ossis” en cap cas no es responsabilitza de les conseqüències d'eventuals interrupcions, ja que no pot garantir la fiabilitat, la disponibilitat ni la continuïtat de la seva pàgina web.`,
                            `En compliment d'allò que disposa la Llei de serveis de la societat de la informació i de comerç electrònic, el titular del lloc web es posa a disposició de tots els usuaris, autoritats i forces de seguretat col·laborant de forma activa en la retirada o, en si s'escau, el bloqueig de tots aquells continguts que poguessin afectar o contravenir la legislació nacional o internacional, drets de tercers o la moral i l'ordre públic.`,
                            `[s]Enllaços de tercers`,
                            `Els enllaços continguts al nostre lloc web poden adreçar també a continguts web de tercers. En aquests casos “Ossis” actua com a prestador de serveis d'intermediació de conformitat amb l'article 17 de la Llei 34/2002 i només serà responsable dels continguts i serveis subministrats a les pàgines web de tercers en la mesura que tingui coneixement efectiu de la il·licitud i no actuï amb diligència per suprimir o inutilitzar l'enllaç.`,
                            `L'objectiu d'aquests enllaços és únicament facilitar-vos la cerca dels recursos que us puguin interessar a través d'Internet. Això no obstant, aquestes pàgines no pertanyen a “Ossis”, ni fa una revisió dels seus continguts i, per això, el titular “Ossis” no assumeix cap responsabilitat pel contingut, informacions o serveis que poguessin aparèixer en aquests llocs. En cas que l'usuari consideri que existeix un enllaç amb contingut il·lícit podrà comunicar-ho a “Ossis” a evafd91@gmail.com sense que en cap cas aquesta comunicació comporti l'obligació de retirar el corresponent enllaç.`,
                            `El titular del lloc web tampoc no es pot fer responsable del funcionament de la pàgina enllaçada o dels possibles danys que puguin derivar-se'n de l'accés o ús d'aquesta.`,
                            `L'establiment de l'enllaç amb una altra pàgina web no suposarà cap tipus d'acord, contracte, patrocini ni recomanació per part de “Ossis”.`,
                            `“Ossis” no es responsabilitza de cap manera ni garanteix la claredat, exactitud, fiabilitat, correcció o moralitat de continguts o serveis que l'establiment de l'enllaç amb una altra pàgina web pugui oferir. L'usuari assumeix sota la seva responsabilitat exclusiva les conseqüències, danys o accions que es puguin derivar de l'accés a la pàgina de l'enllaç.`,
                        ],

                        "Llei Aplicable i jurisdicció": [
                            `La llei aplicable en cas de disputa o conflicte d'interpretació dels termes que conformen aquest Avís Legal, així com qualsevol qüestió relacionada amb els serveis de la web de “Ossis”, serà la llei espanyola.`,
                            `Per a la resolució de qualsevol conflicte que pugui sorgir de l'accés a la pàgina web, l'usuari i “Ossis” en els casos en què la normativa prevegi la possibilitat de sotmetre's a un fur, acorden sotmetre's als jutges i tribunals de la ciutat de Barcelona amb renúncia a qualsevol altre fur general o especial que els pogués correspondre.`,
                        ]
                    }
                },
                es: {
                    title: "AVISO LEGAL",
                    titleDescription: `En cumplimiento con lo establecido en el artículo 10 de la Ley 34/2002 de 11 de Julio, de Servicios de la Sociedad de la Información y el Comercio Electrónico (LSSICE), se informa de los siguientes aspectos legales:`,
                    sections: {
                        "Propiedad del sitio web": [
                            `[g]Titular: Eva Fructuoso Díaz`,
                            `[g]DNI: 47925382Y`,
                            `[g]Teléfono: 661 126 636`,
                            `Contacto: evafd91@gmail.com`,
                        ],
                        "Condiciones generales de uso": [
                            `[s]Titular`,
                            `“Ossis”, como titular y responsable del presente sitio web, pone a disposición de los usuarios el presente documento para dar cumplimiento a las obligaciones de la Ley 34/2002, de Servicios de la Sociedad de la Información y del Comercio Electrónico.`,
                            `[s]Objeto`,
                            `La página web de “Ossis” tiene como objeto facilitar al público información de los servicios de fisioterapia que ofrece.`,
                            `[s]Contenidos`,
                            `Con el objeto de mantener actualizada la información publicada en el portal web, los contenidos de este podrán ser modificados, corregidos, eliminados o añadidos en cualquier momento, por lo que será conveniente comprobar la vigencia o exactitud de estos acudiendo siempre a los textos de la página web.`,
                            `El titular del sitio web se reserva la facultad de efectuar, en cualquier momento y sin necesidad de previo aviso, modificaciones y actualizaciones de la información contenida en su web o en la configuración y presentación de ésta.`,
                            `[s]Aceptación`,
                            `El uso de cualquiera de las funcionalidades del sitio web implica la expresa y plena aceptación de las condiciones aquí expuestas, sin perjuicio de aquellas particulares que pudieran aplicarse a algunos de los servicios concretos ofrecidos a través del sitio web.`,
                        ],

                        "Acceso a la página web": [
                            `Como se ha detallado anteriormente, el acceso a la web de “Ossis” es responsabilidad exclusiva de los usuarios y supone aceptar y conocer el aviso legal, la política de cookies, la política de privacidad y las condiciones de compra.`,
                            `[s]Acceso gratuito`,
                            `El acceso a la página web para los usuarios tiene carácter gratuito, salvo que se refiera al coste de la conexión a Internet suministrada por el Proveedor de Acceso a Internet (ISP) contratado por su cuenta.`,
                        ],

                        "Condiciones generales de uso ": [
                            `La utilización de la página web de “Ossis” por cualquier persona atribuye la condición de Usuario. El usuario se compromete a hacer un uso conforme al presente Aviso Legal y a no utilizar la información, actividades y servicios que el “Ossis” pone a su disposición para desarrollar actividades contrarias a las leyes.`,
                            `[s]Contenidos`,
                            `Los contenidos de la página web del “Ossis” son puestos a disposición del usuario como información propia y de terceros, por lo que el “Ossis” pondrá los medios razonables a su disposición para que estos contenidos sean siempre exactos y actualizados.`,
                            `[s]Servicios interactivos`,
                            `En cuanto al uso de los servicios interactivos que permitan la divulgación de contenidos por parte del usuario a través de la página web del “Ossis”, si en algún momento los hubiera, será conforme a la ley y el presente aviso legal. “Ossis” no se hace responsable de la información y contenidos almacenados, a título enunciativo, pero no limitativo, en foros, chats, generadores de blogs, comentarios, redes sociales o cualquier otro medio que permita a terceros publicar contenidos de forma independiente en la página web del prestador. No obstante, y en cumplimiento de lo dispuesto en el art. 11 y 16 de la LSSICE, el prestador se pone a disposición de todos los usuarios, Autoridades y Fuerzas de seguridad, y colaborando de forma activa en la retirada o en su caso bloqueo de todos aquellos contenidos que pudieran afectar o contravenir la legislación nacional, o internacional, derechos de terceros o la moral y el orden público.`,
                            `[s]Información veraz`,
                            `En caso de omunicarse con “Ossis”, el usuario deberá facilitar información veraz, por lo que el usuario garantizará siempre la autenticidad de los datos comunicados. Será responsabilidad del usuario mantener toda la información facilitada actualizada.`,
                            `[s]Usuarios menores de edad`,
                            `Los usuarios menores de edad deben obtener siempre previamente el consentimiento de los padres, tutores o representantes legales, para el uso de los servicios. La responsabilidad en la determinación de los contenidos a los que accede un menor es responsabilidad de los padres, tutores o representantes legales. Para evitar el acceso a contenido no apropiado por internet se recomienda el uso de programas o filtros que limiten el contenido disponible.`,
                            `[s]Uso correcto de la página web`,
                            `El Usuario se compromete a la correcta utilización de la web y utilidades que se le proporcionen conforme a la ley, el presente Aviso Legal y las instrucciones y avisos que se le comuniquen. El Usuario se obliga al uso exclusivo de la web y todos sus contenidos, para fines lícitos y no prohibidos, que no infrinjan la legalidad vigente y/o puedan resultar lesivos de los derechos legítimos de “Ossis”, y/o que puedan causar cualquier daño o perjuicio de forma directa o indirecta.`,
                        ],

                        "Propiedad intelectual, industrial": [
                            `Todos los elementos que forman el sitio web, así como su estructura, diseño, fotografías, iconos, gráficos, imágenes, código fuente, logotipos, marcas y demás signos distintivos que aparecen en la misma, son propiedad intelectual de “Ossis” o en su caso de terceros, salvo que se especifique lo contrario, y están protegidos por los correspondientes derechos de propiedad intelectual e industrial. El usuario no podrá utilizar la marca gráfica o cualquier otro signo distintivo de “Ossis” dentro de su página web salvo en los casos expresamente autorizados por “Ossis”. Estas autorizaciones se pedirán por escrito a “Ossis”. Por todo ello el usuario que acceda a la página web deberá respetar en todo momento todos los derechos de propiedad intelectual e industrial de la página web titularidad de “Ossis” o de terceros.`,
                            `La reproducción total o parcial, uso, distribución y comunicación pública, requiere de la autorización escrita previa por parte de “Ossis”. Cualquier uso no autorizado previamente por parte de “Ossis” será considerado un incumplimiento grave de los derechos de propiedad intelectual o industrial.`,
                            `Igualmente están protegidos por los correspondientes derechos de propiedad intelectual e industrial las imágenes y otros elementos gráficos contenidos en el sitio web. El uso, reproducción, distribución, comunicación pública, transformación o cualquier otra actividad similar o análoga, queda totalmente prohibida salvo que medie previa y expresa autorización del titular.`,
                            `Respecto a las citas de productos y/o servicios de terceros, el titular del sitio web reconoce a favor de sus titulares los correspondientes derechos de propiedad industrial e intelectual no implicando su sola mención o aparición en la web la existencia de derechos ni de responsabilidad alguna sobre los mismos, como tampoco patrocinio o recomendación.`,
                            `[s]Enlaces de terceros a la página de “Ossis”`,
                            `Si un usuario quiere introducir un enlace desde su página web a la página web de “Ossis” deberá obtener autorización de “Ossis”.`,
                            `El titular del sitio web declara su respeto a los derechos de propiedad intelectual e industrial de terceros; por ello, si considera que en nuestro sitio web se pudieran estar violando sus derechos, rogamos se ponga en contacto con el titular de la página web: evafd91@gmail.com.`,
                        ],

                        "Exclusión de garantías y responsabilidad": [
                            `El Usuario responderá por los daños y perjuicios de toda naturaleza que el “Ossis” pueda sufrir como consecuencia del incumplimiento de cualquiera de las obligaciones a las que queda sometido por este Aviso Legal.`,
                        ],

                        "Protección de datos": [
                            `Para aquellos casos que se recaben, traten o almacenen datos personales se hará en conformidad con la Política de Privacidad publicada en la página web y que puede consultar en el pie de esta misma.`,
                        ],

                        "Política de cookies": [
                            `Actualmente la web no utiliza cookies.`,
                        ],

                        "Acceso a la página web": [
                            `“Ossis” no se responsabiliza de los daños y perjuicios de cualquier tipo producidos en el usuario a consecuencia de fallos en las redes de telecomunicaciones que produzcan la suspensión, cancelación o interrupción del servicio de la página web durante la prestación de este o con carácter previo.`,
                        ],

                        "Calidad del servicio y de los contenidos": [
                            `El titular del sitio web no garantiza la inexistencia de errores en el acceso a la web, en su contenido, ni que éste se encuentre actualizado, aunque el titular del sitio web desarrollará sus mejores esfuerzos para, en su caso, evitarlos, subsanarlos o actualizarlos. En caso de que el usuario considere que existe algún contenido que pudiera ser susceptible de esta clasificación, rogamos se ponga en contacto con el titular del sitio web.`,
                            `Tanto el acceso al sitio web, como el uso que se pueda hacer de la información contenida en los mismos, es de exclusiva responsabilidad de quien lo realiza.`,
                            `“Ossis” intentará garantizar siempre el correcto funcionamiento de la página web las 24 horas al día, los 365 días del año, aunque no se puede descartar la posibilidad de que existan determinadas causas de fuerza mayor, catástrofes naturales, huelgas, o cualquier otra circunstancia parecida que hagan imposible el acceso a la página web. Por todo ello, “Ossis” no se hace responsable de los daños y perjuicios de cualquier naturaleza que pudieran ocasionarse a consecuencia de la falta de disponibilidad, mantenimiento y efectivo funcionamiento de la web o de sus servicios y contenidos. “Ossis” no se hace responsable de la existencia de virus, programas maliciosos o lesivos de los contenidos, del uso ilícito, negligente, fraudulento o contrario a este Aviso legal, o de la falta de licitud, calidad, fiabilidad, utilidad y disponibilidad de los servicios prestados por terceros a disposición de los usuarios del sitio web.`,
                            `“Ossis” se reserva el derecho a interrumpir en caso de que lo estime necesario el acceso a su página web en cualquier momento y sin previo aviso, ya sea por motivos de seguridad, de control, de mantenimiento, por errores de suministro eléctrico o por cualquier otra causa. En resumen, “Ossis” en ningún caso se responsabiliza de las consecuencias de eventuales interrupciones, ya que no puede garantizar la fiabilidad, la disponibilidad ni la continuidad de su página web.`,
                            `En cumplimiento de lo dispuesto en la Ley de servicios de la sociedad de la información y de comercio electrónico, el titular del sitio Web se pone a disposición de todos los usuarios, autoridades y fuerzas de seguridad colaborando de forma activa en la retirada o, en su caso, en el bloqueo de todos aquellos contenidos que pudieran afectar o contravenir la legislación nacional o internacional, derechos de terceros o la moral y el orden público.`,
                            `[s]Enlaces de terceros`,
                            `Los enlaces contenidos en nuestro sitio web pueden también dirigir a contenidos web de terceros. En estos casos “Ossis” actúa como prestador de servicios de intermediación de conformidad con el artículo 17 de la Ley 34/2002 y sólo será responsable de los contenidos y servicios suministrados en las páginas web de terceros en la medida que tenga conocimiento efectivo de la ilicitud y no actúe con diligencia para suprimir o inutilizar el enlace.`,
                            `El objetivo de dichos enlaces es únicamente facilitarle la búsqueda de los recursos que le puedan interesar a través de Internet. No obstante, dichas páginas no pertenecen a “Ossis”, ni hace una revisión de sus contenidos y, por ello, el titular “Ossis” no asume ninguna responsabilidad por el contenido, informaciones o servicios que pudieran aparecer en dichos sitios. En el supuesto que el usuario considere que existe un enlace con contenido ilícito podrá comunicarlo a “Ossis” a evafd91@gmail.com sin que en ningún caso esa comunicación conlleve la obligación de retirar el correspondiente enlace.`,
                            `El titular del sitio web tampoco puede hacerse responsable del funcionamiento de la página enlazada o de los posibles daños que puedan derivarse del acceso o uso de esta.`,
                            `El establecimiento del enlace con otra página web no supondrá ningún tipo de acuerdo, contrato, patrocinio ni recomendación por parte de “Ossis”.`,
                            `“Ossis” no se responsabiliza de modo alguno ni garantiza la claridad, exactitud, fiabilidad, corrección o moralidad de contenidos o servicios que el establecimiento del enlace con otra página web pueda ofrecer. El usuario asume bajo su exclusiva responsabilidad las consecuencias, daños o acciones que pudieran derivarse del acceso a la página del enlace.`,
                        ],

                        "Ley Aplicable y jurisdicción": [
                            `La ley aplicable en caso de disputa o conflicto de interpretación de los términos que conforman este Aviso Legal, así como cualquier cuestión relacionada con los servicios de la web de “Ossis”, será la ley española.`,
                            `Para la resolución de cualquier conflicto que pueda surgir del acceso a la página web, el usuario y “Ossis” en los casos en que la normativa prevea la posibilidad de someterse a un fuero, acuerdan someterse a los Jueces y Tribunales de la ciudad de Barcelona, con renuncia a cualquier otro fuero general o especial que les pudiera corresponder.`,
                        ]
                    }
                },
            }
        }
    },
    computed: {
        ...mapGetters(["idSelected", "text", "lang"]),
    },
    methods: {
        ...mapMutations(["setIdSelected"]),
        scrollMeTo(refName) {
            let element = document.querySelector(refName);
            let top = element.offsetTop - 100;
            window.scrollTo(0, top);
        },
        isSectionSubtitle(s) {
            if(s.substring(0,3) == "[s]") {
                return [
                    true,
                    s.substring(3, s.length)
                ]
            }
            if(s.substring(0,3) == "[g]") {
                return [
                    "true",
                    s.substring(3, s.length)
                ]
            }
            return [
                false,
                s
            ]
        }
    },
    mounted() {
        if(this.idSelected.length > 0) {
            this.scrollMeTo(this.idSelected);
            this.setIdSelected("");
        } else {
            setTimeout(function () {
                window.scrollTo(0, 0);
            }, 1);
        }
    },
}
</script>

<style scoped>
.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3rem var(--horizontal-margin);
}

.main-description {
    margin-bottom: 36px;
    text-align: justify;
}

.legal-section-container {
    margin-bottom: 36px;
}

.section-title {
    font-weight: bold;
    margin-bottom: 12px;
    font-size: 1.1rem;
}

.section-item {
    margin-bottom: 12px;
    text-align: justify;
}

.section-subtitle {
    text-decoration: underline;
}

.section-item-group {
    margin-bottom: 0 !important;
}

/* MAX SCREEN 992px */
@media screen and (max-width: 992px) {
    .main-container {
        font-size: 0.9rem;
    }
}

/* MAX SCREEN 420px */
@media screen and (max-width: 420px) {
    
}
</style>