<template>
  <div>
    <PrivacyPolicy />
  </div>
</template>

<script>
import PrivacyPolicy from '@/components/PrivacyPolicy.vue'

export default {
  name: 'PrivacyPolicyView',
  components: {
    PrivacyPolicy
  }
}
</script>
