<template>
  <div>
    <AboutUs/>
  </div>
</template>

<script>
import AboutUs from '@/components/AboutUs.vue'

export default {
  name: 'AboutUsView',
  components: {
    AboutUs
  }
}
</script>
