<template>
  <div>
    <ContactUs />
  </div>
</template>

<script>
import ContactUs from '@/components/ContactUs.vue'

export default {
  name: 'ContactUsView',
  components: {
    ContactUs
  }
}
</script>
