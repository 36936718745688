<template>
    <div class="appointment-btn">
        <div class="appointment-btn-first-hover">
            <div class="appointment-btn-second-hover">
                <div class="icon icon-50 icon-calendar"></div>
                <span>{{ btnText }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "",
    props: ["btnText"],
    data() {
        return {
            
        }
    },    
}
</script>

<style>
.appointment-btn {
    --diameter: 95px;
    position: fixed;
    bottom: 5rem;
    right: 3rem;
    width: var(--diameter);
    height: var(--diameter);
    background: #F2FCFD;
    border-radius: 50%;
    /* border: 10px solid var(--blue-color); */
    opacity: 50%;
    color: var(--blue-color);
    font-size: 0.5rem;
    font-weight: bold;
    transition: 0.5s;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
}

.appointment-btn:hover {
    opacity: 1;
    box-shadow: 0 0 50px 5px var(--blue-color);
}

.appointment-btn-first-hover {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    transition: 0.5s;
}

.appointment-btn-first-hover:hover {
    box-shadow: 0 0 50px 15px white;
}

.appointment-btn-second-hover {
    width: 100%;
    height: 100%;
    /* border: 10px solid var(--blue-color); */
    transition: 0.5s;
    padding: 1rem;
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0 0 0 1px var(--blue-color);
}

.appointment-btn-second-hover:hover {
    box-shadow: 0 0 0 7.5px var(--blue-color);
}


/* MAX SCREEN 1400px */
@media screen and (max-width: 1400px) {

}

/* MAX SCREEN 1200px */
@media screen and (max-width: 1200px) {
    
}

/* MAX SCREEN 992px */
@media screen and (max-width: 992px) {
    .appointment-btn {
        --diameter: 105px;
        bottom: 3rem;
        right: 3rem;
    }
}

/* MAX SCREEN 768px */
@media screen and (max-width: 768px) {
    .appointment-btn {
        --diameter: 70px;
        bottom: 2rem;
        right: 1.5rem;
        font-size: 0.4rem;
        opacity: 1;
    }

    .icon-calendar {
        width: 24px !important;
        height: 24px !important;
        background-size: 24px !important;
    }

    .appointment-btn-second-hover {
        box-shadow: 0 0 0 4px var(--blue-color);
    }

    .appointment-btn-first-hover {
    box-shadow: 0 0 50px 5px white;
}
}

/* MAX SCREEN 576px */
@media screen and (max-width: 576px) {
  
}
</style>