import { Store, createStore } from 'vuex';
import text from "@/text/text.json";

const lang = "cat";

export default createStore({
  state: {
    idSelected: "",
    debug: "false",
    lang: lang,
    text: text[lang],
  },
  getters: {
    idSelected(status) {
      return status.idSelected;
    },
    debug(status) {
      return status.debug == "true" ? true : false;
    },
    lang(state) {
      return state.lang;
    },
    text(state) {
      return state.text;
    }
  },
  mutations: {
    setIdSelected(status, idSelected) {
      status.idSelected = idSelected;
    },
    setLang(state, lang) {
      state.lang = lang;
      state.text = text[lang];
    }
  },
  actions: {
  },
  modules: {
  }
})
