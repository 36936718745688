<template>
    <div class="main">
        <!-- Title of page -->
        <div class="page-title-container">
            {{text['services'].title}}
        </div>

        <!-- Links to sections -->
        <div class="main-link-container flex-wrap">
            <div class="main-link-element" v-for="(x, i) in Object.keys(text['services'].mainLinks)" :key="i">
                <div class="main-link-title">
                    {{ x }}
                </div>
                <div class="main-link-image-container">
                    <div class="inbetween-img-container">
                        <img class="main-link-image" :class="[text['services'].mainLinks[x].imageAlign]" :src="text['services'].mainLinks[x].image">
                    </div>
                </div>
                <button class="main-link-btn" @click="scrollMeTo(text['services'].mainLinks[x].link)">
                    {{ text['services'].mainLinks[x].text }}
                </button>
            </div>
        </div>

        <!-- <hr class="line-separator" /> -->

        <!-- Ambitos de actuacion -->
        <div class="section-container" id="action-areas-container">
            <div class="section-title-container">
                <div class="section-title-background">
                    <span class="section-title-title">
                        {{ text['services'].areasOfActionInfo.title }}
                    </span>
                </div>
                <div class="section-title-text" v-if="text['services'].areasOfActionInfo.description.length != 0">
                    <span class="section-title-description">
                        {{ text['services'].areasOfActionInfo.description }}
                    </span>
                </div>
            </div>
            <div class="section-info-container">
                <div class="section-info-element" v-for="(k, i) in Object.keys(text['services'].areasOfActionInfo.methodsOfTreatement)" :key="i">
                    <div class="section-info-title">
                        {{ k }}
                    </div>
                    <div class="section-info-element-text">
                        <div class="section-info-element-text-container" v-for="(lines, j) in text['services'].areasOfActionInfo.methodsOfTreatement[k]" :key="j">
                            {{ lines }}<br/>
                            <ul class="section-list-container" v-if="Object.keys(text['services'].lists).includes(k+j)">
                                <li v-for="element in text['services'].lists[k+j]" :key="element">
                                    {{ element }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="pictures-container" v-if="Object.keys(text['services'].pictures).includes(k)">
                        <div class="picture-container" v-for="element in text['services'].pictures[k]" :key="element">
                            <img :style="'top:' + element.topPosition +';'" :src="element.picture" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr class="line-separator extra-x-margin line-separator-small-screen" />

        <!-- Fisioterapia info container -->
        <div class="section-container" id="fisio-container">
            <div class="section-title-container">
                <div class="section-title-background section-title-background-right">
                    <span class="section-title-title">
                        {{ text['services'].fisioInfo.title }}
                    </span>
                </div>
                <div class="section-title-text section-title-text-right">
                    <span class="section-title-description section-title-description-right">
                        {{ text['services'].fisioInfo.description }}
                    </span>
                </div>
            </div>
            <div class="section-info-container">
                <div class="section-info-element" v-for="(k, i) in Object.keys(text['services'].fisioInfo.methodsOfTreatement)" :key="i">
                    <div class="section-info-title">
                        {{ k }}
                    </div>
                    <div class="section-info-element-text">
                        <div class="section-info-element-text-container" v-for="(lines, j) in text['services'].fisioInfo.methodsOfTreatement[k]" :key="j">
                            {{ lines }}<br/>
                            <ul class="section-list-container" v-if="Object.keys(text['services'].lists).includes(k+j)">
                                <li v-for="element in text['services'].lists[k+j]" :key="element">
                                    {{ element }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="pictures-container" v-if="Object.keys(text['services'].pictures).includes(k)">
                        <div class="picture-container" v-for="element in text['services'].pictures[k]" :key="element">
                            <img :style="'top:' + element.topPosition +';'" :src="element.picture" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr class="line-separator extra-x-margin line-separator-small-screen" />

        <!-- Osteopatia info container -->
        <div class="section-container" id="osteo-container">
            <div class="section-title-container">
                <div class="section-title-background">
                    <span class="section-title-title">
                        {{ text['services'].osteoInfo.title }}
                    </span>
                </div>
                <div class="section-title-text">
                    <span class="section-title-description">
                        {{ text['services'].osteoInfo.description }}
                    </span>
                </div>
            </div>
            <div class="section-info-container">
                <div class="section-info-element" v-for="(k, i) in Object.keys(text['services'].osteoInfo.methodsOfTreatement)" :key="i">
                    <div class="section-info-title">
                        {{ k }}
                    </div>
                    <div class="section-info-element-text">
                        <div class="section-info-element-text-container" v-for="(text, j) in text['services'].osteoInfo.methodsOfTreatement[k]" :key="j">
                            {{ text }}<br/>
                        </div>
                    </div>
                    <div class="pictures-container" v-if="Object.keys(text['services'].pictures).includes(k)">
                        <div class="picture-container" v-for="element in text['services'].pictures[k]" :key="element">
                            <img :style="'top:' + element.topPosition +';'" :src="element.picture" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr class="line-separator extra-x-margin line-separator-small-screen" />

        <!-- INDIBA info container -->
        <div class="section-container" id="indiba-container">
            <div class="section-title-container">
                <div class="section-title-background section-title-background-right">
                    <span class="section-title-title">
                        {{ text['services'].indibaInfo.title }}
                    </span>
                </div>
                <div class="section-title-text section-title-text-right">
                    <span class="section-title-description section-title-description-right">
                        {{ text['services'].indibaInfo.description }}
                    </span>
                </div>
            </div>
            <div class="section-info-container">
                <div class="section-info-element" v-for="(k, i) in Object.keys(text['services'].indibaInfo.methodsOfTreatement)" :key="i">
                    <div class="section-info-title">
                        {{ k }}
                    </div>
                    <div class="section-info-element-text">
                        <div class="section-info-element-text-container" v-for="(lines, j) in text['services'].indibaInfo.methodsOfTreatement[k]" :key="j">
                            {{ lines }}<br/>
                            <ul class="section-list-container" v-if="Object.keys(text['services'].lists).includes(k+j)">
                                <li v-for="element in text['services'].lists[k+j]" :key="element">
                                    {{ element }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="pictures-container" v-if="Object.keys(text['services'].pictures).includes(k)">
                        <div class="picture-container" v-for="element in text['services'].pictures[k]" :key="element">
                            <img :style="'top:' + element.topPosition +';'" :src="element.picture" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5"></div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "Services",
    data() {
        return {
            // mainLinks: {
            //     "Ámbitos de actuación": {
            //         link: "#action-areas-container",
            //         text: "Más información",
            //         image: "https://caisaludbcn.com/wp-content/uploads/2021/11/Pilates_Mesa-de-trabajo-1-min.png",
            //     },
            //     "Fisioterapia": {
            //         link: "#fisio-container",
            //         text: "Más información",
            //         image: "https://caisaludbcn.com/wp-content/uploads/2021/11/Osteopatia_Mesa-de-trabajo-1-min.png",
            //     },
            //     "Osteopatia": {
            //         link: "#osteo-container",
            //         text: "Más información",
            //         image: "https://caisaludbcn.com/wp-content/uploads/2021/11/personalizado-01-min.png",
            //     }
            // },
            // areasOfActionInfo: {
            //     title: "Ámbitos de actuación",
            //     description: "",
            //     methodsOfTreatement: {
            //         "Articulación temporo mandibular (ATM)": [
            //             " El aparato masticatorio puede considerarse como un elemento independiente, mantiene relaciones neurológicas a través del núcleo del trigémino y mecánicas por medio de las fascias cérvico faciales con sistemas neuromusculares del cuello, así que la pérdida de tono de la musculatura cervical o lateral del cuello puede alterar el proceso masticatorio y manifestarse el dolor orofacial."
            //         ],
            //         "Pediatría": [
            //             "La Fisioterapia pediátrica es aquel tratamiento de cualquier afectación en la infancia, tanto aguda como crónica, que perturba el desarrollo motriz y, por tanto, limita el potencial para la independencia en la vida adulta llegando a interferir en la calidad de vida tanto a corto como largo plazo.",
            //             "En el caso de recién nacidos es muy importante realizar una sesión de fisioterapia y osteopatía ya que durante el parto su cabeza se ha sometido a diferentes presiones por lo que se recomienda una valoración de suturas craneales. Estas suturas, pueden estar perturbando otros sistemas y dar por ejemplo problemas de cólicos o estreñimiento o problemas en el sueño…",
            //             "A quién podemos ayudar?",
            //         ],
            //         "Geriatría": [
            //             "Es importante mantener las funciones físicas y mentales lo mejor posible para tener la máxima independencia, es por ello que se realizan técnicas para el mantenimiento así como ejercicios personalizados según requiera cada paciente."
            //         ],
            //         "Neurología": [
            //             "Este tratamiento tiene como objetivo mejorar las capacidades físicas del paciente, trabajamos adaptando los ejercicios en los movimientos que lleva a cabo en su vida cotidiana. Dependiendo de la afectación del sistema nervioso, se personaliza el tratamiento a cada persona."
            //         ],
            //         "Traumatología": [
            //             "Es una especialidad para lesiones del sistema musculoesquelético, óseo y ligamentoso como esguinces, tendinopatías, sobrecargas musculares, condropatías, fracturas….Con el objetivo de reducir el dolor y la inflamación e ir recuperando la movilidad."
            //         ],
            //         "Deportivo": [
            //             "Es muy importante la prevención de lesiones en el ámbito deportivo, así como la recuperación y readaptación al deporte después de una lesión. Se realizan ejercicios y tratamientos individualizados a cada paciente teniendo en cuenta el estado físico, la postura y el tipo de deporte."
            //         ],
            //     }
            // },
            // text['services'].fisioInfo: {
            //     title: "Fisioterapia",
            //     description: "« Proviene de la unión de las voces griegas “Physis” que significa naturaleza y “Therapehia” que significa tratamiento. Según la OMS es “la ciencia que aplica tratamientos a través de medios físicos mediante ejercicios terapéuticos, masoterapia y electroterapia”. »",
            //     methodsOfTreatement: {
            //         "Tratamiento de puntos gatillo": [
            //             "Existen diferentes técnicas para el tratamiento de los puntos gatillo, siempre nos adaptamos al paciente ya que existen diferentes maneras de abordar los puntos gatillo. Estiramientos,crioterapia, punción seca, digito presión, masoterapia, técnicas de músculo energía, ejercicio controlado…"
            //         ],
            //         "Estiramientos": [
            //             "Con el fin de relajar la musculatura y aumentar la circulación sanguínea en músculos y articulaciones realizamos estiramientos. Desde el centro OSSIS os enseñaremos cuál es el mejor momento para estirar, que se debe estirar, de qué manera y en qué frecuencia."
            //         ],
            //         "Electroterapia": [
            //             "El TENS (estimulación eléctrica nerviosa transcutánea) proporciona buenos resultados en casos de dolor agudo y crónico de muchos tipos. Los impulsos eléctricos colocados en la zona dolorida o cerca de ella, estimulan los nervios para bloquear las señales de dolor al cerebro y, así, el dolor no es percibido. Con el TENS a baja frecuencia se liberan endorfinas, la substancia de alivio del dolor propia del cuerpo.",
            //             "NMES ( estimulación eléctrica neuromuscular) se utiliza como complemento del entrenamiento deportivo a todos los niveles. Los impulsos eléctricos estimulan los nervios para enviar señales a un músculo específico que reacciona contrayéndose de la misma forma que la actividad muscular normal."
            //         ],
            //         "Vendajes": [
            //             "Dependiendo del tipo de lesión realizaremos un vendaje u otro, existen varios vendajes, por ejemplo cuando se trata de lesiones del tipo esguince y es necesario inmovilizar la articulación utilizaremos una venda adhesiva.",
            //             "También podemos utilizar el Kinesiotape, este tipo de vendaje es muy elástico y se adapta a la estructura. Tiene muchas funciones, dependiendo de la manera de aplicarlo puede ayudar a la relajación muscular, o por el contrario a la estimulación de un músculo en concreto."
            //         ],
            //         "Auriculoterapia": [
            //             "Estas técnicas se utilizan como complementarias a la fisioterapia y a la osteopatía.",
            //             "La auriculoterapia forma parte de la medicina alternativa,consiste en estimular determinados puntos en la oreja mediante agujas o semillas las distintas partes del cuerpo se ven reflejadas en la oreja, así que se puede utilizar para el tratamiento de una dolencia o para ayudar en problemas de insomnio, estrés."
            //         ],
            //         "Reflexologia podal": [
            //             "Es otra técnica que utilizamos además del tratamiento de fisioterapia, mediante la reflexología podal podemos activar la circulación sanguínea de todo el cuerpo , ayuda a liberar tensiones y puede ser una técnica complementaria al tratamiento de un dolor muscular o incluso en estreñimiento o estrés."
            //         ],
            //         "Drenaje linfático": [
            //             "Es una técnica de masoterapia muy suave que se aplica en los ganglios y vasos del sistema linfático  para estimular la circulación y reabsorción del líquido. Se utiliza para reducir edemas o la retención de líquido. Funciona muy bien en patologías que producen hinchazón, edema o linfedemas en alguna extremidad."
            //         ],
            //         "Invasivas": [
            //             "Punción Seca, aplicación percutánea de un estímulo (aguja) para conseguir como respuesta una contracción involuntaria y refleja.",
            //         ],
            //     }
            // },
            // text['services'].osteoInfo: {
            //     title: "Osteopatía",
            //     description: "« Combinación de la palabra griega osteo o “hueso” y de palabra griega \"pathy\" que significa sentir. La osteopatía propone un concepto holístico de visión global del cuerpo, con sistemas completamente conectados. »",
            //     methodsOfTreatement: {
            //         "Craneal": [
            //             "A nivel craneal existen cambios de conformación tensionales. La osteopatía trata de llevar al equilibrio los huesos craneales así como todos los tejidos de su alrededor(fascias, músculos…) mediante técnicas muy suaves e incluso relajantes.",
            //             "La terapia craneal puede ayudar a reducir dolores o tensiones estructurales aunque también se utilizan estas técnicas para problemas en ATM, bruxismo y estrés… Las técnicas craneales pueden ayudar a reducir dolores de cabeza y/o migrañas."
            //         ],
            //         "Visceral": [
            //             "No sólo existen los dolores musculares, cuando existen alteraciones viscerales también pueden dar dolor referido en otras partes del cuerpo, como por ejemplo un dolor de hombro derecho puede venir por afectación del hígado.",
            //             "Es común  pensar, que tenemos una hernia discal y que pinza el nervio y por ello tenemos una ciática con irradiación al miembro inferior, pero cuando se trata de la espalda, puede ocurrir lo mismo, puede haber alteración en una víscera y repercutir en otra zona, otro ejemplo sería en el caso del estómago que puede dar dolor referido hacia la zona de esternón, la zona epigástrica e incluso a la zona interescapular, 3era costilla o manifestarse en la zona de los trapecios. Por ello es importante realizar un buen diagnóstico diferencial y aplicar las técnicas adaptadas a cada paciente."
            //         ],
            //         "Aparato locomotor": [
            //             "Las alteraciones en el aparato locomotor pueden llevar a presentar dolor o restricción de movimiento en huesos, articulaciones, ligamentos,músculos, tendones y nervios. Con la osteopatía se realizan una combinación de técnicas para ayudar a disminuir ese dolor y restablecer las funciones de estos sistemas."
            //         ],
            //     }
            // },
            // lists: {
            //     "Pediatría2": [
            //         "Bebés con sufrimiento fetal perinatal",
            //         "Craneosinostosis, deformidades craneales y problemas del sueño.",
            //         "Otitis,obstrucción del canal lagrimal.",
            //         "Espasticidad, rigidez, hipotonía o distonías.",
            //         "Miopatías.",
            //         "Frenillos.",
            //         "Sdes y malformaciones genéticas.",
            //         "Deformidades de la columna vertebral, escoliosis, cifosis...",
            //         "PCI.",
            //         "Cólicos del lactante, gases, estreñimiento,disquecia, reflujo.",
            //         "Problemas de atención y en el desarrollo motriz."
            //     ],
            //     "Invasivas0": [
            //         "Reduce la concentración de sustancias de estímulos dolorosos,disminuyendo el dolor local y referido.",
            //         "Relaja la musculatura.",
            //         "Revascularización.",
            //         "Restauración de patrones normales de movimiento.",
            //         "Como técnica complementaria a la punción seca se realiza electroacupuntura aplicando electrodos a las agujas.",
            //     ],
            // }
        }
    },
    computed: {
        ...mapGetters(["idSelected", "text"]),
    },
    methods: {
        ...mapMutations(["setIdSelected"]),
        scrollMeTo(refName) {
            let element = document.querySelector(refName);
            let top = element.offsetTop - 150;
            window.scrollTo(0, top);
        },
    },
    mounted() {
        if(this.idSelected.length > 0) {
            this.scrollMeTo(this.idSelected);
            this.setIdSelected("");
        } else {
            setTimeout(function () {
                window.scrollTo(0, 0);
            }, 1);
        }
    },
}
</script>

<style scoped>
.main {
    overflow: hidden;
}

.pictures-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
    margin: 2rem 0;
}

.picture-container {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 15px 0 var(--blue-color);
}

.picture-container > img {
    width: 100%;
    position: absolute;
}
</style>