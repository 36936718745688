<template>
    <div class="maintenance-container">
        <div class="language-container">
            <span class="icon icon-24 icon-lang icon-cat" @click="setLang('cat')"></span>
            <span class="icon icon-24 icon-lang icon-spain" @click="setLang('spain')"></span>
        </div>
        <div class="logo-container">
            <!-- <div class="logo-bg"></div> -->
            <span class="icon icon-maintenance icon-logo"></span>
        </div>
        <p>
            {{ languages[lang].text1 }}
            <br class="my-2"/>
            {{ languages[lang].text2 }}
        </p>
        <a class="appointment-btn" :href="languages[lang].btnLink">
            {{ languages[lang].btnText }}
        </a>
    </div>
</template>

<script>
export default {
    name: "Maintenance",
    data() {
        return {
            lang: "cat",
            languages: {
                cat: {
                    text1: "Pròximament web de OSSIS...",
                    text2: "Per reservar cita ara mateix fes click al següent botó",
                    btnLink: "javascript:abrirCitaOnline('6e416d5e902d5abf368e7168e8d5f3dd-1','cat')",
                    btnText : "RESERVAR ARA",
                },
                spain: {
                    text1: "Próximamente web de OSSIS...",
                    text2: "Para reservar cita ahora pulse el siguiente botón",
                    btnLink: "javascript:abrirCitaOnline('6e416d5e902d5abf368e7168e8d5f3dd-1','es')",
                    btnText : "RESERVAR AHORA",
                },
            }
        }
    },
    methods: {
        setLang(lang) {
            this.lang = lang;
        }
    },
}
</script>

<style scoped>
.maintenance-container {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 202, 224, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 15px;
    font-size: 1.5rem;
    padding: 0 5rem;
}

.logo-container {
    position: relative;
}

.logo-bg {
    position: absolute;
    left: 150px;
    top: 150px;
    /* --value: 300px;
    width: 300px;
    height: 300px;
    background-size: 300px; */
    background-color: white;
    border: 1px solid white;
    border-radius: 50%;
    box-shadow: 0 0 50px 200px white;
}

.icon-maintenance {
    --value: 300px;
    width: var(--value);
    height: var(--value);
    background-size: var(--value);
    position: relative;
    z-index: 4;
}

.appointment-btn {
    color: white;
    text-decoration: none;
    background-color: red;
    padding: 15px 50px;
    border-radius: 15px;
    font-weight: bold;
    transition: 0.5s;
}

.appointment-btn:hover {
    background-color: orange;
}


@media only screen and (max-width: 600px) {
    .maintenance-container {
        gap: 25px;
        font-size: 1.25rem;
        padding: 0 1.15rem;
    }

    .icon-maintenance {
        --value: 150px;
    }
}
</style>