<template>
    <div>
        <!-- Title of page -->
        <div class="page-title-container">
            {{text["contactUs"].title}}
        </div>

        <div class="main-container">
            <div class="contact-container">
                <div class="contact-content-container">
                    <div class="contact-info-container">
                        <div class="contact-info-item">
                            <div class="icon icon-24 icon-phone"></div>
                            <span class="contact-item-text">93 146 56 79</span>
                        </div>
                        <div class="contact-info-item">
                            <div class="icon icon-24 icon-whatsapp"></div>
                            <span class="contact-item-text">66 112 66 36 ({{text["contactUs"].onlyWhatsapp}})</span>
                        </div>
                        <div class="contact-info-item">
                            <div class="icon icon-24 icon-instagram"></div>
                            <span class="contact-item-text">@ossis.es</span>
                        </div>
                        <div class="contact-info-item">
                            <div class="icon icon-24 icon-email"></div>
                            <span class="contact-item-text">ossis@fisioterapeutes.org</span>
                        </div>
                        <div class="contact-info-item">
                            <div class="icon icon-24 icon-location"></div>
                            <span class="contact-item-text">{{text["contactUs"].address}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <hr class="line-separator y-margins line line-separator-small-screen" />

            <div class="location-container">
                <iframe class="location-map-container" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2988.283793881786!2d1.8346874756268148!3d41.49812448911976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4893a5ba3978d%3A0x45b784b8e183c809!2sOssis!5e0!3m2!1ses!2sfr!4v1712123345232!5m2!1ses!2sfr" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "ContactUs",
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters(["idSelected", "text"]),
    },
    methods: {
        ...mapMutations(["setIdSelected"]),
        scrollMeTo(refName) {
            let element = document.querySelector(refName);
            let top = element.offsetTop - 100;
            window.scrollTo(0, top);
        }
    },
    mounted() {
        if(this.idSelected.length > 0) {
            this.scrollMeTo(this.idSelected);
            this.setIdSelected("");
        } else {
            setTimeout(function () {
                window.scrollTo(0, 0);
            }, 1);
        }
    },
}
</script>

<style scoped>
.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3rem var(--horizontal-margin);
}

.line {
    width: calc(100% - var(--horizontal-margin) - 10rem);
}

.contact-info-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-size: 1.1rem;
}

.contact-info-item {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.location-container {
    width: 75%;
}

.location-map-container {
    width: 100%;
    aspect-ratio: 1.75;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: 0 5px 10px -5px var(--blue-color);
}

/* MAX SCREEN 992px */
@media screen and (max-width: 992px) {
    .location-container {
        width: 100%;
    }

    .contact-info-container {
        font-size: 0.9rem;
    }
}

/* MAX SCREEN 768px */
@media screen and (max-width: 768px) {
    .location-container {
        margin-top: 1rem;
    }

    .contact-container {
        margin-bottom: 1rem;
    }
}
</style>