import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AboutUsView from '../views/AboutUsView.vue';
import ContactUsView from '../views/ContactUsView.vue';
import ServicesView from '../views/ServicesView.vue';
import LegalWarningView from '../views/LegalWarningView.vue';
import PrivacyPolicyView from '../views/PrivacyPolicyView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/conocenos',
    name: 'about-us',
    component: AboutUsView
  },
  {
    path: '/contacto',
    name: 'contact-us',
    component: ContactUsView
  },
  {
    path: '/servicios',
    name: 'services',
    component: ServicesView
  },
  {
    path: '/aviso-legal',
    name: 'aviso-legal',
    component: LegalWarningView
  },
  {
    path: '/politica-privacidad',
    name: 'politica-privacidad',
    component: PrivacyPolicyView
  },
]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

export default router
