<template>
    <div>
        <!-- Title of page -->
        <div class="page-title-container">
            {{textLang[lang].title}}
        </div>

        <div class="main-container">
            <table class="table-container">
                <tr class="table-row">
                    <td class="table-cell fw-bold" colspan="2">{{textLang[lang].basicInfo.title}}</td>
                </tr>
                <tbody v-for="i in Object.keys(textLang[lang].basicInfo.elements)" :key="i">
                    <tr class="table-row" v-for="t,j in textLang[lang].basicInfo.elements[i].content" :key="j">
                        <td class="table-cell fw-bold" :rowspan="textLang[lang].basicInfo.elements[i].content.length" v-if="j==0">
                            {{textLang[lang].basicInfo.elements[i].title}}
                        </td>
                        <td class="table-cell">
                            {{t}}
                        </td>
                    </tr>
                </tbody>
            </table>

            <div>
                <div class="legal-section-container" v-for="k,i in Object.keys(textLang[lang].firstTextContent)" :key="i">
                    <div class="section-title">{{k}}</div>
                    <div>
                        <div class="section-item" :class="[isSectionList(t)[0] ? 'section-item-list' : '']" v-for="t,j in textLang[lang].firstTextContent[k]" :key="j">
                            {{isSectionList(t)[1]}}
                        </div>
                    </div>
                </div>
            </div>

            <table class="table-container">
                <tr v-for="i in Object.keys(textLang[lang].secondTable)" :key="i">
                    <td class="table-cell second-table-cell" :class="[i==1 ? 'fw-bold' : '']">
                        {{textLang[lang].secondTable[i].col1}}
                    </td>
                    <td class="table-cell second-table-cell" :class="[i==1 ? 'fw-bold' : '']">
                        {{textLang[lang].secondTable[i].col2}}
                    </td>
                    <td class="table-cell second-table-cell" :class="[i==1 ? 'fw-bold' : '']">
                        {{textLang[lang].secondTable[i].col3}}
                    </td>
                </tr>
            </table>
            
            <div>
                <div class="legal-section-container" v-for="k,i in Object.keys(textLang[lang].secondTextContent)" :key="i">
                    <div class="section-title">{{k}}</div>
                    <div>
                        <div class="section-item" :class="[isSectionList(t)[0] === true ? 'section-item-list' : '', isSectionList(t)[0] === 'true' ? 'section-item-group' : '']" v-for="t,j in textLang[lang].secondTextContent[k]" :key="j">
                            {{ isSectionList(t)[1]}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "PrivacyPolicy",
    data() {
        return {
            textLang: {
                cat: {
                    title: "POLÍTICA DE PRIVACITAT",
                    basicInfo: {
                        title: "Informació bàsica sobre Protecció de Dades",
                        elements: {
                            1: {
                                title: "Responsable",
                                content: [
                                    "Eva Fructuoso Díaz",
                                    "DNI: 47925382Y",
                                    "C/ Major, num. 2, 08782, La Beguda Alta"
                                ]
                            },
                            2: {
                                title: "Contacte",
                                content: [
                                    "Telf: 661 126 636",
                                    "evafd91@gmail.com",
                                ]
                            },
                            3: {
                                title: "Finalitat",
                                content: [
                                    "1) Consultes i sol·licituds d'informació de tercers: Donar resposta a la sol·licitud plantejada.",
                                    "2) Reserva de cita.",
                                ]
                            },
                            4: {
                                title: "Legitimació",
                                content: [
                                    "1) Consultes i sol·licituds d'informació de tercers: Interès legítim d'atenció a l'interessat.",
                                    "2) Reserva de cita: Interès legítim d'atenció a l'interessat.",
                                ]
                            },
                            5: {
                                title: "Conservació",
                                content: [
                                    "La informació demanada de la persona interessada serà conservada mentre sigui necessària per complir amb la finalitat per a la qual van ser recollides les dades personals, de manera que, una vegada complerta la finalitat les dades seran cancel·lades. Aquesta cancel·lació donarà lloc al bloqueig de les dades conservant-se únicament a disposició de les AAPP, jutges i tribunals, per atendre les possibles responsabilitats nascudes del tractament, durant el termini de prescripció d'aquestes, complert el termini esmentat es procedirà a la destrucció de la informació. A la segona capa informativa es detallen els terminis de conservació de la informació en relació a diferents matèries.",
                                ]
                            },
                            6: {
                                title: "Procedència",
                                content: [
                                    "1) Consultes i sol·licituds d'informació de tercers: El mateix interessat o el seu representant legal.",
                                    "2) Reserva de cita: El mateix interessat o el seu representant legal.",
                                ]
                            },
                            7: {
                                title: "Destinataris",
                                content: [
                                    "Aquestes dades seran tractades per “Ossis”. Compartim les vostres dades amb prestadors de serveis que ens ajuden o donen suport (encarregats del tractament), amb els quals s'ha celebrat el preceptiu contracte en els termes de l'article 28 del RGPD.",
                                ]
                            },
                            8: {
                                title: "Transferències internacionals",
                                content: [
                                    "No realitzem transferències internacionals amb les vostres dades.",
                                ]
                            },
                            9: {
                                title: "Drets",
                                content: [
                                    "Accés, rectificació, oposició, supressió, limitació del tractament i portabilitat de les dades personals, com s'explica al corresponent apartat.",
                                ]
                            },
                            10: {
                                title: "Informació addicional",
                                content: [
                                    "Podeu consultar la informació addicional i detallada sobre Protecció de Dades en el desglossament d'apartats que us presentem a continuació (segona capa informativa).",
                                ]
                            }
                        }
                    },
                    firstTextContent: {
                        "Informació general": [
                            "Aquesta Política de Privadesa té com a finalitat donar a conèixer les condicions que regeixen la recollida i tractament de les seves dades personals per part de la nostra entitat per vetllar pels drets fonamentals, el seu honor i llibertats, tot això en compliment de les normatives vigents que regulen la Protecció de Dades personals: el Reglament 2016/679, del Parlament Europeu i del Consell, de 27 d'abril de 2016, relatiu a la protecció de les persones físiques pel que fa al tractament de dades personals i a la circulació d'aquestes dades, (d'ara endavant RGPD); i la Llei Orgànica 3/2018, del 5 de desembre, de Protecció de Dades Personals i garantia dels drets digitals (d'ara endavant LOPDGDD).",
                            "De conformitat amb aquestes normatives, necessitem disposar de la vostra autorització i consentiment per a la recollida i el tractament de les vostres dades personals, per la qual cosa a continuació, us indiquem tots els detalls del vostre interès respecte a com realitzem aquests processos, amb quines finalitats, que altres entitats podrien tenir accés a les vostres dades i quins són els vostres drets.",
                        ],
                        "Responsable. Qui és el Responsable del tractament de les vostres dades?": [
                            "El Responsable del Tractament de Dades és aquella persona física o jurídica, de naturalesa pública o privada, o òrgan administratiu, que sol o conjuntament amb altres determini les finalitats i els mitjans del tractament de dades personals.",
                            "Us informem que Eva Fructuoso Díaz (d'ara endavant “Ossis”) és la titular de la web ossis.es, amb DNI: 47925382Y i adreça al C/ Major, num. 2, 08782, La Beguda Alta. Podeu contactar per correu electrònic a evafd91@gmail.com o bé telefònicament al 661 126 636.",
                            "Les dades personals que se sol·licitin, si s'escau, consistiran únicament en aquelles estrictament imprescindibles per identificar i atendre la sol·licitud realitzada per la persona titular dels mateixos, d'ara endavant l'interessat. Aquesta informació serà tractada de manera lleial, lícita i transparent en relació amb l'interessat. D'altra banda, les dades personals seran recollides per a finalitats determinades explícites i legítimes, i no seran tractades ulteriorment de manera incompatible amb aquestes finalitats.",
                            "Les dades recollides de cada interessat seran adequades, pertinents i no excessives en relació a les finalitats corresponents per a cada cas, i seran actualitzades sempre que sigui necessari.",
                            "La persona titular de les dades serà informada, amb caràcter previ a la recollida de les seves dades, dels extrems generals regulats en aquesta política per tal que pugui prestar el consentiment exprés, precís i inequívoc per al tractament de les seves dades, si aquest fos necessari i conforme als aspectes següents.",
                        ],
                        "Finalitat. Amb quina finalitat tractem les vostres dades personals?": [
                            "La informació personal que ens faciliti a través dels correus electrònics i als formularis es mantindrà confidencial i protegida. En cap cas no es destinarà o utilitzarà per a altres finalitats, ni es lliurarà a terceres persones sense el seu consentiment, d'acord amb els principis del RGPD 679/2016 i la L.O. 3/2018. Per complir les diferents disposicions legals, hem establert diferents nivells de seguretat per protegir les dades personals que ens facilitin, havent adoptat totes les mesures tècniques al nostre abast per evitar la pèrdua, mal ús, alteració, accés no autoritzat i manipulació d'aquestes dades personals.",
                            "A continuació, detallem els usos i finalitats previstes:",
                            "[l]1) Consultes i sol·licituds d'informació de tercers: Donar resposta a la sol·licitud plantejada.",
                            "[l]2) Reserva de cita.",
                        ],
                        "Legitimació. Quina és la legitimació per al tractament de les vostres dades?": [
                            "La recollida i el tractament de les vostres dades està legitimat sempre per una o diverses bases jurídiques, les quals detallem a continuació:",
                            "[l]1) Consultes i sol·licituds d'informació de tercers: Interès legítim d'atenció a l'interessat.",
                            "[l]2) Reserva de cita: Interès legítim d'atenció a l'interessat.",
                        ],
                        "Conservació. Per quant de temps conservarem les vostres dades?": [
                            "Utilitzem les vostres dades durant el temps estrictament necessari per complir les finalitats indicades anteriorment. A continuació, us detallem el temps que conservem les dades:",
                            "A títol informatiu es recullen els terminis de conservació de la informació en relació amb diferents matèries:",
                        ],
                    },
                    secondTable: {
                        1: {
                            col1: "DOCUMENT",
                            col2: "TERMINI",
                            col3: "REF. LEGAL",
                        },
                        2: {
                            col1: "Documentació de caràcter laboral o relacionada amb la seguretat social",
                            col2: "4 anys",
                            col3: "Article 21 del Real Decreto Legislatiu 5/2000, de 4 de agosto, por el que s'aprova el text refós de la Llei sobre Infraccions i Sanciones en l'Ordre Social",
                        },
                        3: {
                            col1: "Documentació comptable i fiscal a efectes mercantils",
                            col2: "6 anys",
                            col3: "Art. 30 Codi de Comerç",
                        },
                        4: {
                            col1: "Documentació comptable i fiscal a efectes fiscals",
                            col2: "4 anys",
                            col3: "Articles 66 a 70 Llei General Tributaria",
                        },
                        5: {
                            col1: "Dades per l'enviament d'informació sobre serveis",
                            col2: "Mentre l'interessat no sol·liciti la supressió",
                            col3: "No aplicable",
                        },
                        6: {
                            col1: "Dades de persones que han contactat",
                            col2: "Mentre no sol·liciti la supressió",
                            col3: "No aplicable",
                        },
                        7: {
                            col1: "Històries Clíniques",
                            col2: "15 anys",
                            col3: "Lleu 16/2010, de 3 de juny, de modificació de la Llei 21/2000 sobre els drets d'informació relatius a la salut, l'autonomia del pacient i la documentació clínica",
                        }
                    },
                    secondTextContent: {
                        "Procedència. Com hem obtingut les vostres dades?": [
                            "Visitar la web de “Ossis” no suposa que l'usuari estigui obligat a facilitar cap informació sobre si mateix.",
                            "“Ossis” prohibeix expressament que els menors de divuit anys facilitin dades personals sense el consentiment previ dels seus pares o tutors.",
                            "A continuació, us indiquem la procedència de les dades:",
                            "[l]1) Consultes i sol·licituds d'informació de tercers: El mateix interessat o el seu representant legal.",
                            "[l]2) Reserva de cita: El mateix interessat o el seu representant legal. Per a realitzar la reserva serà derivat a la nostra eina de gestió de cites Nucli Software, i li sol·licitarem la data de naixement, el nom i cognoms, el telèfon i l'adreça de correu electrònic.",
                        ],
                        "Mesures de seguretat. Què fem per protegir les vostres dades?": [
                            "“Ossis” adopta les mesures organitzatives i tècniques necessàries per garantir la seguretat i la privadesa de les seves dades, evitar la seva alteració, pèrdua, tractament o accés no autoritzat, depenent de l'estat de la tecnologia, la naturalesa de les dades emmagatzemades i els riscos al fet que estan exposats.",
                            "Entre altres, destaquen les mesures següents:",
                            "[l]· Garantir la confidencialitat, integritat, disponibilitat i resiliència permanents dels sistemes i serveis de tractament.",
                            "[l]· Restaurar la disponibilitat i l'accés a les dades personals de manera ràpida, en cas d'incident físic o tècnic.",
                            "[l]· Verificar, avaluar i valorar, de manera regular, l'eficàcia de les mesures tècniques i organitzatives implementades per garantir la seguretat del tractament.",
                            "[l]· Seudonimitzar i xifrar les dades personals, en cas que es tracti de dades sensibles.",
                        ],
                        "Destinataris. A quins destinataris es comunicaran les vostres dades?": [
                            "Aquestes dades seran tractades per “Ossis”. Compartim les vostres dades amb prestadors de serveis que ens ajuden o donen suport (encarregats del tractament), amb els quals s'ha celebrat el preceptiu contracte en els termes de l'article 28 del RGPD.",
                            "Per a la gestió d'agenda el derivarem a la nostra plataforma, gestionada per:",
                            "[g]NUCLI SOFTWARE SL",
                            "[g]NIF: B66260001",
                            "[g]C/Casanova, 195 Ent. 3a, 08036 Barcelona,",
                            "[g]934190535",
                            "[g]rgpd@nuclisoftware.com",
                            "https://nuclisoftware.com/es/politica-privacidad.html",
                            "No realitzem transferències internacionals amb les vostres dades personals.",
                        ],
                        "Drets. Quins són els vostres drets quan ens faciliteu les vostres dades?": [
                            "La normativa vigent de protecció de dades us empara en una sèrie de drets en relació amb l'ús que donem a les vostres dades personals. Tots i cadascun dels seus drets són unipersonals i intransferibles, és a dir, que únicament poden ser exercits pel titular de les dades, amb la comprovació prèvia de la seva identitat.",
                            "A continuació, us indiquem quins són els drets que us assisteixen:",
                            "[l]· Sol·licitar l'accés a les vostres dades personals.",
                            "[l]· Sol·licitar la rectificació de les vostres dades.",
                            "[l]· Sol·licitar la supressió o eliminació de les vostres dades (dret a «l'oblit»).",
                            "[l]· Limitar o oposar-se a l'ús que donem a les vostres dades.",
                            "[l]· Dret a la portabilitat de les vostres dades per a casos de serveis de telecomunicacions o internet.",
                            "[l]· Dret a retirar el seu consentiment en qualsevol moment.",
                            "[l]· Dret a presentar una reclamació en matèria de protecció de dades davant de l'Autoritat de Control: Agència Espanyola de Protecció de Dades.",
                            "Com podeu exercir els vostres drets?",
                            "Per a l'exercici dels vostres drets d'accés, rectificació, supressió, limitació o oposició, portabilitat i retirada del vostre consentiment, podeu fer-ho de la manera següent:",
                            "Presencialment o per correu electrònic a:",
                            "[g]Eva Fructuoso Díaz",
                            "[g]DNI: 47925382Y",
                            "evafd91@gmail.com",
                            "A més dels drets que us assisteixen, si creieu que les vostres dades no s'estan demanant o tractant d'acord amb la normativa vigent de Protecció de Dades, podreu realitzar una reclamació davant l'Autoritat de Control, les dades de contacte de les quals indiquem a continuació:",
                            "[g]Agència Espanyola de Protecció de Dades",
                            "[g]C/ Jorge Juan, 6. 28001, Madrid.",
                            "[g]Email: info@agpd.es",
                            "[g]Telèfon: 912663517",
                            "Web: https://www.agpd.es",
                        ],
                        "Consentiment i acceptació": [
                            "L'acceptació del present document indica que vostè entén i accepta totes les clàusules de la nostra política de privacitat pel que autoritza la recollida i el tractament de les seves dades personals en aquests termes.",
                        ],
                    }
                },
                es: {
                    title: "POLÍTICA DE PRIVACIDAD",
                    basicInfo: {
                        title: "Información básica sobre Protección de Datos",
                        elements: {
                            1: {
                                title: "Responsable",
                                content: [
                                    "Eva Fructuoso Díaz",
                                    "DNI: 47925382Y",
                                    "C/ Major, num. 2, 08782, La Beguda Alta"
                                ]
                            },
                            2: {
                                title: "Contacto",
                                content: [
                                    "Telf: 661 126 636",
                                    "evafd91@gmail.com",
                                ]
                            },
                            3: {
                                title: "Finalidad",
                                content: [
                                    "1) Consultas y solicitudes de información de terceros: Dar respuesta a la solicitud planteada.",
                                    "2) Reserva de cita.",
                                ]
                            },
                            4: {
                                title: "Legitimación",
                                content: [
                                    "1) Consultas y solicitudes de información de terceros: Interés legítimo de atención al interesado.",
                                    "2) Reserva de cita: Interés legítimo de atención al interesado.",
                                ]
                            },
                            5: {
                                title: "Conservación",
                                content: [
                                    "La información recabada de la persona interesada será conservada mientras sea necesaria para cumplir con la finalidad para la cual fueron recabados los datos personales, de forma que, una vez cumplida la finalidad los datos serán cancelados. Dicha cancelación dará lugar al bloqueo de los datos conservándose únicamente a disposición de las AAPP, Jueces y Tribunales, para atender las posibles responsabilidades nacidas del tratamiento, durante el plazo de prescripción de éstas, cumplido el citado plazo se procederá a la destrucción de la información. En la segunda capa informativa se detallan los plazos de conservación de la información en relación con diferentes materias.",
                                ]
                            },
                            6: {
                                title: "Procedencia",
                                content: [
                                    "1) Consultas y solicitudes de información de terceros: El propio interesado o su representante legal.",
                                    "2) Reserva de cita: El propio interesado o su representante legal.",
                                ]
                            },
                            7: {
                                title: "Destinatarios",
                                content: [
                                    "Estos datos serán tratados por “Ossis”. Compartimos sus datos con prestadores de servicios que nos ayudan o dan soporte (encargados del tratamiento), con los que se ha celebrado el preceptivo contrato en los términos del artículo 28 del RGPD.",
                                ]
                            },
                            8: {
                                title: "Transferencias internacionales",
                                content: [
                                    "No realizamos transferencias internacionales con sus datos.",
                                ]
                            },
                            9: {
                                title: "Derechos",
                                content: [
                                    "Acceso, rectificación, oposición, supresión, limitación del tratamiento y portabilidad de los datos personales, como se explica en el correspondiente apartado.",
                                ]
                            },
                            10: {
                                title: "Información adicional",
                                content: [
                                    "Puede consultar la información adicional y detallada sobre Protección de Datos en el desglose de apartados que le presentamos a continuación (segunda capa informativa).",
                                ]
                            }
                        }
                    },
                    firstTextContent: {
                        "Información general": [
                            "La presente Política de Privacidad tiene como finalidad dar a conocer las condiciones que rigen la recogida y tratamiento de sus datos personales por parte de nuestra entidad para velar por los derechos fundamentales, su honor y libertades, todo ello en cumplimiento de las normativas vigentes que regulan la Protección de Datos personales: el Reglamento 2016/679, del Parlamento Europeo y del Consejo, del 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la circulación de estos datos, (en adelante RGPD); y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (en adelante LOPDGDD).",
                            "De conformidad con dichas normativas, necesitamos disponer de su autorización y consentimiento para la recogida y el tratamiento de sus datos personales, por lo que a continuación, le indicamos todos los detalles de su interés respecto a cómo realizamos estos procesos, con qué finalidades, que otras entidades podrían tener acceso a sus datos y cuáles son sus derechos.",
                        ],
                        "Responsable. ¿Quién es el Responsable del tratamiento de sus datos?": [
                            "El Responsable del Tratamiento de Datos es aquella persona física o jurídica, de naturaleza pública o privada, u órgano administrativo, que solo o conjuntamente con otros determine los fines y medios del tratamiento de datos personales.",
                            "Le informamos que Eva Fructuoso Díaz (en adelante “Ossis”) es la titular de la web ossis.es, con DNI: 47925382Y y dirección en la C/ Major, num. 2, 08782, La Beguda Alta. Puede contactar por correo electrónico en evafd91@gmail.com o bien telefónicamente al 661 126 636.",
                            "Los datos personales que se soliciten, en su caso, consistirán únicamente en aquellos estrictamente imprescindibles para identificar y atender la solicitud realizada por la persona titular de los mismos, en adelante el interesado. Dicha información será tratada de forma leal, lícita y transparente en relación con el interesado. Por otra parte, los datos personales serán recogidos para finalidades determinadas explícitas y legítimas, no siendo tratados ulteriormente de manera incompatible con dichos fines.",
                            "Los datos recogidos de cada interesado serán adecuados, pertinentes y no excesivos en relación con las finalidades correspondientes para cada caso, y serán actualizados siempre que sea necesario.",
                            "La persona titular de los datos será informada, con carácter previo a la recogida de sus datos, de los extremos generales regulados en esta política a fin de que pueda prestar el consentimiento expreso, preciso e inequívoco para el tratamiento de sus datos, si éste fuese necesario y conforme a los siguientes aspectos.",
                        ],
                        "Finalidad. ¿Con qué finalidad tratamos sus datos personales?": [
                            "La información personal que nos facilite a través de los correos electrónicos y en los formularios se mantendrá confidencial y protegida. En ningún caso se destinará o utilizará para otros fines, ni se entregará a terceras personas sin su consentimiento, de acuerdo con los principios del RGPD 679/2016 y la L.O. 3/2018. Para cumplir con las distintas disposiciones legales, hemos establecido diferentes niveles de seguridad para proteger los datos personales que nos faciliten, habiendo adoptado todas las medidas técnicas a nuestro alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y manipulación de dichos datos personales.",
                            "A continuación, le detallamos los usos y finalidades previstas:",
                            "[l]1) Consultas y solicitudes de información de terceros: Dar respuesta a la solicitud planteada.",
                            "[l]2) Reserva de cita.",
                        ],
                        "Legitimación. ¿Cuál es la legitimación para el tratamiento de sus datos?": [
                            "La recogida y el tratamiento de sus datos está legitimado siempre por una o varias bases jurídicas, las cuales detallamos a continuación:",
                            "[l]1) Consultas y solicitudes de información de terceros: Interés legítimo de atención al interesado.",
                            "[l]2) Reserva de cita: Interés legítimo de atención al interesado.",
                        ],
                        "Conservación. ¿Por cuánto tiempo conservaremos sus datos?": [
                            "Utilizamos sus datos durante el tiempo estrictamente necesario para cumplir las finalidades indicadas anteriormente. A continuación, le detallamos el tiempo que conservamos los datos:",
                            "A título informativo se recogen los plazos de conservación de la información en relación con diferentes materias:",
                        ],
                    },
                    secondTable: {
                        1: {
                            col1: "DOCUMENTO",
                            col2: "PLAZO",
                            col3: "REF. LEGAL",
                        },
                        2: {
                            col1: "Documentación de carácter laboral o relacionada con la seguridad social",
                            col2: "4 años",
                            col3: "Artículo 21 del Real Decreto Legislativo 5/2000, de 4 de agosto, por el que se aprueba el texto refundido de la Ley sobre Infracciones y Sanciones en el Orden Social",
                        },
                        3: {
                            col1: "Documentación contable y fiscal a efectos mercantiles",
                            col2: "6 años",
                            col3: "Art. 30 Código Comercio",
                        },
                        4: {
                            col1: "Documentación contable y fiscal a efectos fiscales",
                            col2: "4 años",
                            col3: "Artículos 66 a 70 Ley General Tributaria",
                        },
                        5: {
                            col1: "Datos para el envío de información sobre servicios",
                            col2: "Mientras el interesado no solicite su supresión",
                            col3: "No aplicable",
                        },
                        6: {
                            col1: "Datos de personas que han contactado",
                            col2: "Mientras no solicite su supresión",
                            col3: "No aplicable",
                        },
                        7: {
                            col1: "Historias Clínicas",
                            col2: "15 años",
                            col3: "Ley 16/2010, de 3 de junio, de modificación de la Ley 21/2000 sobre los derechos de información relativos a la salud, la autonomía del paciente y la documentación clínica",
                        }
                    },
                    secondTextContent: {
                        "Procedencia. ¿Cómo hemos obtenido sus datos?": [
                            "Visitar la web de “Ossis” no supone que el usuario esté obligado a facilitar ninguna información sobre sí mismo. ",
                            "“Ossis” prohíbe expresamente que los menores de dieciocho años faciliten Datos Personales sin el consentimiento previo de sus padres o tutores. ",
                            "A continuación, le indicamos la procedencia de los datos:",
                            "[l]1) Consultas y solicitudes de información de terceros: El propio interesado o su representante legal.",
                            "[l]2) Reserva de cita: El propio interesado o su representante legal. Para realizar la reserva será dirigido a nuestra herramienta de gestión de citas Nucli Software, y le solicitaremos la fecha de nacimiento, el nombre y apellidos, el teléfono y la dirección de correo electrónico.",
                        ],
                        "Medidas de seguridad. ¿Qué hacemos para proteger sus datos?": [
                            "“Ossis” adopta las medidas organizativas y técnicas necesarias para garantizar la seguridad y la privacidad de sus datos, evitar su alteración, pérdida, tratamiento o acceso no autorizado, dependiendo del estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que están expuestos. ",
                            "Entre otras, destacan las siguientes medidas:",
                            "[l]· Garantizar la confidencialidad, integridad, disponibilidad y resiliencia permanentes de los sistemas y servicios de tratamiento.",
                            "[l]· Restaurar la disponibilidad y el acceso a los datos personales de forma rápida, en caso de incidente físico o técnico.",
                            "[l]· Verificar, evaluar y valorar, de forma regular, la eficacia de las medidas técnicas y organizativas implementadas para garantizar la seguridad del tratamiento.",
                            "[l]· Seudonimizar y cifrar los datos personales, en caso de que se trate de datos sensibles.",
                        ],
                        "Destinatarios. ¿A qué destinatarios se comunicarán sus datos?": [
                            "Estos datos serán tratados por “Ossis”. Compartimos sus datos con prestadores de servicios que nos ayudan o dan soporte (encargados del tratamiento), con los que se ha celebrado el preceptivo contrato en los términos del artículo 28 del RGPD.",
                            "Para la gestión de agenda le derivaremos a nuestra plataforma, gestionada por:",
                            "[g]NUCLI SOFTWARE SL",
                            "[g]NIF: B66260001",
                            "[g]C/Casanova, 195 Ent. 3a, 08036 Barcelona,",
                            "[g]934190535",
                            "[g]rgpd@nuclisoftware.com",
                            "https://nuclisoftware.com/es/politica-privacidad.html",
                            "No realizamos transferencias internacionales con sus datos personales.",
                        ],
                        "Derechos. ¿Cuáles son sus derechos cuando nos facilita sus datos?": [
                            "La normativa vigente de protección de datos le ampara en una serie de derechos en relación con el uso que le damos a sus datos personales. Todos y cada uno de sus derechos son unipersonales e intransferibles, es decir, que únicamente pueden ser ejercidos por el titular de los datos, previa comprobación de su identidad.",
                            "A continuación, le indicamos cuales son los derechos que le asisten:",
                            "[l]· Solicitar el acceso a sus datos personales.",
                            "[l]· Solicitar la rectificación de sus datos.",
                            "[l]· Solicitar la supresión o eliminación de sus datos (derecho al «olvido»).",
                            "[l]· Limitar u oponerse al uso que le damos a sus datos.",
                            "[l]· Derecho a la portabilidad de sus datos para casos de servicios de telecomunicaciones o internet.",
                            "[l]· Derecho a retirar su consentimiento en cualquier momento.",
                            "[l]· Derecho a presentar una reclamación en materia de protección de datos ante la Autoridad de Control: Agencia Española de Protección de Datos.",
                            "¿Cómo puede ejercer sus derechos?",
                            "Para el ejercicio de sus derechos de acceso, rectificación, supresión, limitación u oposición, portabilidad y retirada de su consentimiento, puede hacerlo de la siguiente forma:",
                            "Presencialmente o por correo electrónico en:",
                            "[g]Eva Fructuoso Díaz",
                            "[g]DNI: 47925382Y",
                            "evafd91@gmail.com",
                            "Además de los derechos que le asisten, si cree que sus datos no se están recabando o tratando conforme a la normativa vigente de Protección de Datos, usted podrá realizar una reclamación ante la Autoridad de Control, cuyos datos de contacto indicamos a continuación: ",
                            "[g]Agencia Española de Protección de Datos ",
                            "[g]C/ Jorge Juan, 6. 28001, Madrid.",
                            "[g]Email: info@agpd.es",
                            "[g]Teléfono: 912663517",
                            "Web: https://www.agpd.es",
                        ],
                        "Consentimiento y aceptación": [
                            "La aceptación del presente documento indica que usted entiende y acepta todas las cláusulas de nuestra política de privacidad por lo que autoriza la recogida y el tratamiento de sus datos personales en estos términos.",
                        ],
                    }
                }
            }
        }
    },
    computed: {
        ...mapGetters(["idSelected", "text", "lang"]),
    },
    methods: {
        ...mapMutations(["setIdSelected"]),
        scrollMeTo(refName) {
            let element = document.querySelector(refName);
            let top = element.offsetTop - 100;
            window.scrollTo(0, top);
        },
        isSectionList(s) {
            if(s.substring(0,3) == "[l]") {
                return [
                    true,
                    s.substring(3, s.length)
                ]
            }
            if(s.substring(0,3) == "[g]") {
                return [
                    "true",
                    s.substring(3, s.length)
                ]
            }
            return [
                false,
                s
            ]
        }
    },
    mounted() {
        if(this.idSelected.length > 0) {
            this.scrollMeTo(this.idSelected);
            this.setIdSelected("");
        } else {
            setTimeout(function () {
                window.scrollTo(0, 0);
            }, 1);
        }
    },
}
</script>

<style scoped>
.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3rem var(--horizontal-margin);
}

.table-container,
.table-row,
.table-cell {
    border-collapse: collapse;
    border: 1px solid var(--blue-color);
}

.table-cell {
    padding: 10px;
    text-align: justify;
}

.second-table-cell {
    text-align: center !important;
}

.table-container,
.legal-section-container {
    margin-bottom: 36px;
}

.section-title {
    font-weight: bold;
    margin-bottom: 12px;
    font-size: 1.1rem;
}

.section-item {
    margin-bottom: 12px;
    text-align: justify;
}

.section-item-list {
    padding-left: 20px;
}

.section-item-group {
    margin-bottom: 0 !important;
}

/* MAX SCREEN 992px */
@media screen and (max-width: 992px) {
    .main-container {
        font-size: 0.9rem;
    }

    .table-cell {
        font-size: 0.8rem;
        text-align: start;
    }
}

/* MAX SCREEN 768px */
@media screen and (max-width: 768px) {
    .section-item-list {
        text-align: start;
    }
}
</style>