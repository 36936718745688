<template>
  <div>
    <LegalWarning />
  </div>
</template>

<script>
import LegalWarning from '@/components/LegalWarning.vue'

export default {
  name: 'LegalWarningView',
  components: {
    LegalWarning
  }
}
</script>
