<template>
    <!-- <div class="hidden">
        <img src="@/assets/images/eva-profileLQ.jpg" alt="">
        <img src="@/assets/images/alejandro-profileLQ.jpg" alt="">
        <img src="@/assets/images/teamLQ.jpg" alt="">
        <img src="@/assets/images/ambitosLQ.jpg" alt="">
        <img src="@/assets/images/aparato-locomotorLQ.jpg" alt="">
        <img src="@/assets/images/auriculoterapiaLQ.jpg" alt="">
        <img src="@/assets/images/centro1LQ.jpg" alt="">
        <img src="@/assets/images/centro1LQ.jpg" alt="">
        <img src="@/assets/images/centro2LQ.jpg" alt="">
        <img src="@/assets/images/craneal1LQ.jpg" alt="">
        <img src="@/assets/images/deportivo1LQ.jpg" alt="">
        <img src="@/assets/images/fisioLQ.jpg" alt="">
        <img src="@/assets/images/geriatria1LQ.jpg" alt="">
        <img src="@/assets/images/invasivas1LQ.jpg" alt="">
        <img src="@/assets/images/pediatria1LQ.jpg" alt="">
        <img src="@/assets/images/pediatria2LQ.jpg" alt="">
        <img src="@/assets/images/pediatria3LQ.jpg" alt="">
        <img src="@/assets/images/pediatria4LQ.jpg" alt="">
        <img src="@/assets/images/reflex-podal1LQ.jpg" alt="">
        <img src="@/assets/images/vendajesLQ.jpg" alt="">
        <img src="@/assets/images/visceral1LQ.jpg" alt="">
        <img src="@/assets/images/indiba1LQ.jpg" alt="">
        <img src="@/assets/images/indiba2LQ.jpg" alt="">
        <img src="@/assets/images/indiba3LQ.jpg" alt="">
    </div> -->
    <div v-if="debug">
        <maintenance />
    </div>
    <div class="main-home-container" v-else>
        <div class="first-row">
            <div class="background-image-container">
                <div class="image-background"></div>
                <img class="background-image" src="@/assets/images/home-image-2.jpg" alt="">
            </div>
            <div class="home-title-container">
                <!-- <span class="main-title">OSSIS</span> -->
                <span class="title-image"></span>
                <div class="sub-title-container">
                    <span class="sub-title">{{ text['home']['subtitle-1'] }}</span>
                    <span class="sub-title">{{ text['home']['subtitle-2'] }}</span>
                </div>
            </div>
        </div>

        <!-- SERVICES links -->
        <div class="margin-top">
            <div class="second-row-title-container text-center">
                {{ text['home'].services }}
            </div>
            <div class="main-link-container flex-wrap">
                <div class="main-link-element" v-for="(x, i) in Object.keys(text['home'].servicesLinks)" :key="i">
                    <div class="main-link-title">
                        {{ x }}
                    </div>
                    <div class="main-link-image-container">
                        <div class="inbetween-img-container">
                            <img class="main-link-image" :class="[text['home'].servicesLinks[x].imageAlign]" :src="text['home'].servicesLinks[x].image">
                        </div>
                    </div>
                    <router-link class="main-link-btn" :to="{name: 'services'}" @click="setIdSelected(text['home'].servicesLinks[x].link)">
                        {{ text['home'].servicesLinks[x].text }}
                    </router-link>
                </div>
            </div>
        </div>

        <hr class="line-separator extra-x-margin y-margins line-separator-small-screen" />

        <!-- ABOUT US links -->
        <div class="margin-top">
            <div class="second-row-title-container text-center">
                {{ text['home'].aboutUs }}
            </div>
            <div class="main-link-container">
                <div class="main-link-element" v-for="(x, i) in Object.keys(text['home'].aboutUsLinks)" :key="i">
                    <div class="main-link-title">
                        {{ x }}
                    </div>
                    <div class="main-link-image-container">
                        <div class="inbetween-img-container">
                            <img class="main-link-image" :class="[text['home'].aboutUsLinks[x].imageAlign]" :src="text['home'].aboutUsLinks[x].image">
                        </div>
                        
                    </div>
                    <router-link class="main-link-btn" :to="{name: 'about-us'}" @click="setIdSelected(text['home'].aboutUsLinks[x].link)">
                        {{ text['home'].aboutUsLinks[x].text }}
                    </router-link>
                </div>
            </div>
        </div>

        <hr class="line-separator y-margins extra-x-margin line-separator-small-screen" />

        <!-- CONTACT US link -->
        <div class="second-row">
            <div class="second-row-col contact-container">
                <div class="second-row-title-container">
                    {{ text['home'].contactUs }}
                </div>
                <div class="contact-content-container">
                    <div class="contact-info-container">
                        <div class="contact-info-item">
                            <div class="icon icon-24 icon-phone"></div>
                            <span class="contact-item-text">93 146 56 79</span>
                        </div>
                        <div class="contact-info-item">
                            <div class="icon icon-24 icon-whatsapp"></div>
                            <span class="contact-item-text">66 112 66 36 ({{text['home'].onlyWhatsapp}})</span>
                        </div>
                        <div class="contact-info-item">
                            <div class="icon icon-24 icon-instagram"></div>
                            <span class="contact-item-text">@ossis.es</span>
                        </div>
                        <div class="contact-info-item">
                            <div class="icon icon-24 icon-email"></div>
                            <span class="contact-item-text">ossis@fisioterapeutes.org</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="second-row-col location-container">
                <div>
                    <div class="second-row-title-container">
                        {{ text['home'].location }}
                    </div>
                    <div class="location-content-container">
                        <div class="location-info-container">
                            <span class="location-item-text"><div class="icon icon-24 icon-location"></div>{{ text['home']["locationText-1"] }}</span>
                            <span class="location-item-text">La Beguda Alta</span>
                            <span class="location-item-text">08782</span>
                        </div>
                    </div>
                </div>
                <iframe class="location-map-container" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2988.283793881786!2d1.8346874756268148!3d41.49812448911976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4893a5ba3978d%3A0x45b784b8e183c809!2sOssis!5e0!3m2!1ses!2sfr!4v1712123345232!5m2!1ses!2sfr" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Maintenance from "@/components/Maintenance.vue";

export default {
    name: "Home",
    components: {
        Maintenance,
    },
    data() {
        return {
            // servicesLinks: {
            //     "Ámbitos de actuación": {
            //         link: "#action-areas-container",
            //         text: "Más información",
            //         image: "https://caisaludbcn.com/wp-content/uploads/2021/11/Pilates_Mesa-de-trabajo-1-min.png",
            //     },
            //     "Fisioterapia": {
            //         link: "#fisio-container",
            //         text: "Más información",
            //         image: "https://caisaludbcn.com/wp-content/uploads/2021/11/Osteopatia_Mesa-de-trabajo-1-min.png",
            //     },
            //     "Osteopatia": {
            //         link: "#osteo-container",
            //         text: "Más información",
            //         image: "https://caisaludbcn.com/wp-content/uploads/2021/11/personalizado-01-min.png",
            //     }
            // },
            // text['home'].aboutUsLinks: {
            //     "Eva Fructuoso Díaz": {
            //         link: "#team-container",
            //         text: "Más información",
            //         image: "https://fisart.es/wp-content/uploads/2020/03/eva.jpg",
            //     },
            //     "El centro": {
            //         link: "#center-container",
            //         text: "Más información",
            //         image: "https://www.fisio-poblenou.com/wp-content/uploads/2021/02/2_1.jpg",
            //     },
            // },
        }
    },
    computed: {
        ...mapGetters(["debug", "text"]),
    },
    methods: {
        ...mapMutations(["setIdSelected"]),
    },
    mounted() {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 1);
    },
}
</script>

<style scoped>
.main-home-container {
    --bg-img-width: 1400;
    --bg-img-height: 760;
    --bg-height: 500;
    --bg-img-ratio: calc(var(--bg-img-height) / var(--bg-img-width));
    --bg-ratio: calc(var(--bg-height) / var(--bg-img-width));
}

.first-row {
    background-color: rgba(0, 0, 0, 0.3);
    
    position: relative;
    width: 100%;
    height: calc(var(--bg-ratio) * 100vw);
    overflow: hidden;
}

.image-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(var(--bg-ratio) * 100vw);
    background-size: 100%;
    background-color: rgba(0, 0, 0, 0.35);
    z-index: -1;
}

.background-image {
    position: absolute;
    top: calc(((var(--bg-ratio) - var(--bg-img-ratio)) * 100vw) * 0.5);
    left: 0;
    width: 100%;
    z-index: -2;
}

.home-title-container {
    color: white;
    margin-top: 50px;
    margin-left: 200px;
}

.main-title {
    font-size: 7rem;
    font-weight: bold;
}

.sub-title-container {
    display: flex;
    flex-direction: column;
    margin-left: 250px;
    margin-top: -3rem;
}

.sub-title {
    font-size: 3rem;
    font-weight: bold;
}

.title-image {
    --value: 500px;
    display: block;
    background-image: url("@/assets/logos/logo OSSIS - letras blancas solas.svg");
    background-repeat: no-repeat;
    width: var(--value);
    height: calc( var(--value) * 0.5);
    background-size: var(--value);
    background-position: center;
}

.margin-top {
    margin-top: 5.5rem;
}

.second-row {
    display: flex;
    justify-content: space-between;
    margin: 2rem;
    margin-left: var(--horizontal-margin);
    margin-right: var(--horizontal-margin);
}

.second-row-col {
    /* background-color: rgba(0, 0, 0, 0.3); */
    padding: 2.5rem;
    border-radius: 15px;
}

.contact-container {
    width: 35%;
}

.second-row-title-container {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    margin-top: -0.5rem;
}

.contact-info-item {
    margin-top: 1rem;
    margin-left: 1rem;
    display: flex;
    gap: 1rem;
}

.location-container {
    width: 65%;
    display: flex;
    gap: 2rem;
}

.location-content-container {
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;
}

.location-info-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: end;
    /* margin-top: 30px; */
    /* font-size: 1.1rem; */
}

.location-item-text {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.location-map-container {
    width: 100%;
    aspect-ratio: 1.75;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: 0 5px 10px -5px var(--blue-color);
}


/* MAX SCREEN 1400px */
@media screen and (max-width: 1400px) {
  .home-title-container {
    margin-top: 25px;
  }

  .contact-container {
    width: 40%;
  }

  .location-container {
    width: 60%;
  }
}

/* MAX SCREEN 1200px */
@media screen and (max-width: 1200px) {
  .home-title-container {
    margin-top: 0px;
  }

  .title-image {
    --value: 450px;
  }

  .second-row {
    flex-direction: column;
    gap: 1rem;
  }

  .contact-container {
    width: auto;
    margin: 0 auto;
    text-align: center
  }

  .location-container {
    width: 100%;
  }
}

/* MAX SCREEN 992px */
@media screen and (max-width: 992px) {
  .home-title-container {
    margin-left: 100px;
  }

  .title-image {
    --value: 350px;
  }

  .sub-title {
    font-size: 2rem;
  }

  .contact-container {
    font-size: 0.9rem;
  }

  .location-info-container {
    flex-direction: row;
    gap: 5px;
    width: auto;
    font-size: 0.9rem;
  }

  .location-container {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .margin-top {
    margin-top: 3rem;
  }
}

/* MAX SCREEN 768px */
@media screen and (max-width: 768px) {
  .home-title-container {
    margin-left: 75px;
  }

  .title-image {
    --value: 300px;
    margin-top: -5px;
    margin-bottom: 10px;
  }

  .sub-title {
    font-size: 1.5rem;
  }
}

/* MAX SCREEN 576px */
@media screen and (max-width: 576px) {
    .main-home-container {
        --bg-height: 705;
    }

    .second-row {
        margin-top: 3.25rem;
    }

    .contact-container {
        padding: 0;
    }

    .contact-info-item {
        margin-left: 0;
    }

    .location-container {
        padding: 0;
        margin-top: 2.5rem;
    }
}

/* MAX SCREEN 500px */
@media screen and (max-width: 500px) {
    .home-title-container {
        margin-left: 30px;
    }

    .title-image {
        margin-top: -5px;
    }

    .sub-title-container {
        margin-left: 200px;
    }
}

/* MAX SCREEN 450px */
@media screen and (max-width: 450px) {
    .home-title-container {
        margin-left: 15px;
    }

    .title-image {
        margin-top: -10px;
    }

    .sub-title-container {
        margin-left: 195px;
    }
}

/* MAX SCREEN 410px */
@media screen and (max-width: 410px) {
    .home-title-container {
        margin-left: 8px;
    }

    .title-image {
        margin-top: -25px;
    }

    .sub-title-container {
        margin-left: 180px;
    }
}

/* MAX SCREEN 380px */
@media screen and (max-width: 380px) {
    .home-title-container {
        margin-left: 0px;
    }

    .title-image {
        margin-top: -25px;
    }

    .sub-title-container {
        margin-left: 150px;
    }
}
</style>